import { Col, Radio, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { success, error } from "../../contants/snackbars";
import { colorScheme } from "../../contants/variables";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import {
  addCarProduct,
  updateNewCarProduct,
} from "../../stores/api_calls/carlists";
import { uploadImage } from "../../stores/api_calls/upload";
import { MobileView, WebView } from "./Views";
import { convertToRaw } from "draft-js";

const PreviewNewCar = (props) => {
  const { isEdit, form, selectedFiles, selectedFile1, selectedFile2 } = props;
  const [view, setView] = useState(0);
  const [requestLoading, setRequestLoading] = useState(false);
  const [localImageUrls, setLocalImageUrls] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedFiles.length) {
      const localImg = selectedFiles.map((file) => URL.createObjectURL(file));
      setLocalImageUrls(localImg);
    }
  }, [selectedFiles]);

  const onViewChange = (e) => {
    setView(e.target.value);
  };

  const onSubmit = async () => {

    const handleUpload = async (file, key) => {
      if (file.file) {
        try {
          const res = await uploadImage(file.file);
          if (res.data.success) {
            form[key] = res?.data?.data?.metadata?.image_url || null;
          }
        } catch (e) {
          error(e.response.data.server_response);
        }
      }
    };

    
    const getDescEditorContent = (content) => {
      if(content.hasOwnProperty('blocks')) {
        return content;
      } else {
        const rawDraftContentStateDesc = content ? JSON.stringify(convertToRaw(content.getCurrentContent())) : null;
        return JSON.parse(rawDraftContentStateDesc)
      }
    };

    // const rawDraftContentStateSpecs = form.specsFormatted
    //   ? JSON.stringify(convertToRaw(form.specsFormatted.getCurrentContent()))
    //   : null;

    // const rawDraftContentStateFeat = form.featFormatted
    //   ? JSON.stringify(convertToRaw(form.featFormatted.getCurrentContent()))
    //   : null;

    // const rawDraftContentStateDesc = form.descFormatted
    //   ? JSON.stringify(convertToRaw(form.descFormatted.getCurrentContent()))
    //   : null;

    form.specs = getDescEditorContent(form.specsFormatted);
    // form.feat = JSON.parse(rawDraftContentStateFeat);
    form.desc = getDescEditorContent(form.descFormatted);

    setRequestLoading(true);
    const imagesIds = isEdit
      ? form.imageUrls.map((img) => img._id["$oid"])
      : [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const waterMark = true;
        const directSeller = form.direct_seller;
        const onUpload = uploadImage(file, index + 1, waterMark, directSeller);

        onUpload
          .then(async (res) => {
            if (res.data.success) {
              imagesIds.push(res.data.data._id);
              // console.log('images',imagesIds)
            }

            if (
              imagesIds.length - form.imageUrls.length ===
              selectedFiles.length
            ) {

              await handleUpload(selectedFile1, "longAdImage1");
              await handleUpload(selectedFile2, "longAdImage2");

              const car = isEdit
                ? updateNewCarProduct(form, imagesIds)
                : addCarProduct(form, imagesIds);

              car
                .then((res) => {
                  success(`Bike ${isEdit ? "updated" : "added"} Successfully`);
                  setRequestLoading(false);
                  navigate("../bikes/verified-new", { replace: true });
                })
                .catch((e) => {
                  error(e.response.data.server_response);
                  setRequestLoading(false);
                });
            }
          })
          .catch((e) => {
            error(e.response.data.server_response);
            setRequestLoading(false);
          });
      });
    } else {
      await handleUpload(selectedFile1, "longAdImage1");
      await handleUpload(selectedFile2, "longAdImage2");

      const car = isEdit
        ? updateNewCarProduct(form, imagesIds)
        : addCarProduct(form, imagesIds);
      car
        .then((res) => {
          success(`Bike ${isEdit ? "updated" : "added"} Successfully`);
          setRequestLoading(false);
          navigate("../bikes/verified-new", { replace: true });
        })
        .catch((e) => {
          error(e.response.data.server_response);
          setRequestLoading(false);
        });
    }
  };

  return (
    <div>
      <Row>
        <Col span={8}>
          <div className="preview-text-container">
            <p className="preview-header">
              Choose the device you want to preview
            </p>
            <Radio.Group onChange={onViewChange} value={view}>
              <Radio value={0}>Web Application</Radio>
              <Radio value={1}>Mobile Application</Radio>
            </Radio.Group>

            <p className="preview-note">
              If you are happy with the result kindly click button below.
            </p>

            <div className="button-container">
              {/* <PrimaryButton className="button" color={colorScheme.secondary} label="Save as Draft" onClick={onSaved}/> */}
              {requestLoading ? (
                <LoadingButton />
              ) : (
                <PrimaryButton
                  className="button"
                  color={colorScheme.primary}
                  label={isEdit ? "Update this Ad" : "Create this Ad"}
                  onClick={onSubmit}
                />
              )}
            </div>
          </div>
        </Col>

        <Col span={16}>
          <div className="preview-container">
            <p style={{ marginBottom: "10px" }}>
              Hover on the screen and scroll to view contents
            </p>
            {!view ? (
              <WebView form={form} localImageUrls={localImageUrls} />
            ) : (
              <MobileView form={form} localImageUrls={localImageUrls} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewNewCar;
