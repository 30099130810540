import { Checkbox, Input, Modal } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { error, success } from "../../contants/snackbars";
import { addPlan, editPlan } from "../../stores/api_calls/packages";
import { generateInclusionLabel } from "../../stores/helpers/packageFormHelpers";
import "./plateNumber.scss";
import { UserContext } from "../../stores/contexts/userContext";
import {
  addPlateNumber,
  editPlateNumber,
} from "../../stores/api_calls/plateNumbers";
import PhoneInput from "react-phone-input-2";

const { TextArea } = Input;

const PlateNumberForm = ({
  visible,
  isEditing,
  data,
  onCancel,
  addPlateNumberSuccess,
}) => {
  const [userConfig, setUserConfig] = useContext(UserContext);
  const profile = userConfig.userDetails;
  const [requestLoading, setRequestLoading] = useState(false);

  const [form, setForm] = useState({
    plateNumber: "",
    price: "",
    status: true,
    inquiryType: "",
    email: "",
    contactNumber: "",
    fullname: "",
  });

  useEffect(() => {
    if (isEditing) {
      setForm({
        id: data.id,
        plateNumber: data.car_plate_number,
        price: data.price,
        status: data.status,
        inquiryType: data.inquiry_type,
        email: data.email,
        contactNumber: data.contact_no,
        fullname: data.car_plate_owner_name,
        isSold: data.is_sold,
      });
    }
  }, [isEditing]);

  const handleFieldChange = (name, e) => {
    if (name === "contactNumber") {
      setForm({ ...form, [name]: e });
    } else {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  const onSubmit = () => {
    const createCarPlateNumber = addPlateNumber(form);
    createCarPlateNumber
      .then((res) => {
        if (res.data.success) {
          success("Bike Plate Number For Sale Created Successfully");
          addPlateNumberSuccess();
          setRequestLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        error("Failed to Add Bike Plate Number For Sale");
        setRequestLoading(false);
      });
  };

  const onUpdate = () => {
    const updatePlateNumber = editPlateNumber(form);
    updatePlateNumber
      .then((res) => {
        if (res.data.success) {
          success("Plate number updated successfully");
          addPlateNumberSuccess();
          setRequestLoading(false);
        }
      })
      .catch((e) => {
        error(`Opps! Update failed. Please try again later`);
      });
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEditing ? "Edit" : "Sell"} Bike Plate Number`}
        visible={visible}
        onOk={isEditing ? onUpdate : onSubmit}
        onCancel={onCancel}
        okText={`${isEditing ? "Save" : "Submit"}`}
      >
        <div className="field-container">
          <p className="field-label">Plate Number : </p>
          <Input
            value={form.plateNumber}
            maxLength={12}
            placeholder="Type Plate Number"
            onChange={(text) => handleFieldChange("plateNumber", text)}
          />
        </div>
        <div className="field-container">
          <p className="field-label">Price : </p>
          <Input
            type="number"
            value={form.price}
            placeholder="Type Price"
            onChange={(text) => handleFieldChange("price", text)}
          />
        </div>

        <div className="field-container">
          <p className="field-label">Description : </p>
          <TextArea
            rows={4}
            value={form.inquiryType}
            placeholder="Type Description"
            onChange={(text) => handleFieldChange("inquiryType", text)}
          />
        </div>

        <div className="field-container">
          <p className="field-label">Owner's Fullname : </p>
          <Input
            value={form.fullname}
            placeholder="Type Owner's Fullname"
            onChange={(text) => handleFieldChange("fullname", text)}
          />
        </div>
        <div className="field-container">
          <p className="field-label">Email : </p>
          <Input
            value={form.email}
            placeholder="Email"
            onChange={(text) => handleFieldChange("email", text)}
          />
        </div>
        <div className="field-container">
          <p className="field-label">Phone Number : </p>
          <PhoneInput
            country={"sg"}
            value={form.contactNumber}
            onChange={(e) => handleFieldChange("contactNumber", e)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PlateNumberForm;
