import axios from 'axios'
import Kjur from '../utils/jwt';
import moment from 'moment';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchBanners = async (data) => {

    const obj = {
        page_type: data.page_type ?? null,
        banner_type: data.banner_type ?? null,
        status: data.status ?? null,
        page:data.page,
        limit:data.limit,
        keyword:data.keyword
    }

    const encodedData = Kjur.encode(obj)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/banners?token=${encodedData}`,
        axiosConfig
    )
}

export const addBanner = async (banner) => {

    const data = {
        name: banner.bannerName,
        ad_type: banner.adType,
        banner_type: banner.bannerType,
        page_type: banner.pageType,
        client_name: banner.clientName,
        link : banner.url,
        is_external_link : banner.is_external_link,
        start_date: moment(banner.startDate).format("YYYY-MM-DD"),
        end_date: moment(banner.endDate).format("YYYY-MM-DD"),
        banner_image_id: banner.imageId ? banner.imageId : null,
        ads_location: banner.adsLocation,
        set_timer: parseInt(banner.timer),
        banner_video: banner.bannerVideo ? banner.bannerVideo : null,
        desktop_banner_image_url: banner.imageUrl ? banner.imageUrl : null,
        tablet_banner_image_url: banner.tabletImageUrl ? banner.tabletImageUrl : null,
        mobile_banner_image_url: banner.mobileImageUrl ? banner.mobileImageUrl : null,

        video_type: banner.videoType ? banner.videoType : null,
        local_video_url: banner.localVideoUrl ? banner.localVideoUrl : null,
        local_video_id: banner.localVideoId ? banner.localVideoId : null,
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/banners`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const editBanner = async (banner) => {

    const data = {
        id:banner.bannerId,
        name: banner.bannerName,
        client_name: banner.clientName,
        link : banner.url,
        is_external_link : banner.is_external_link,
        ad_type: banner.adType,
        banner_type: banner.bannerType,
        page_type: banner.pageType,
        start_date: moment(banner.startDate).format("YYYY-MM-DD"),
        end_date: moment(banner.endDate).format("YYYY-MM-DD"),
        banner_image_id: banner.imageId ? banner.imageId : null,
        ads_location: banner.adsLocation,
        set_timer: parseInt(banner.timer),
        banner_video: banner.bannerVideo ? banner.bannerVideo : null,
        desktop_banner_image_url: banner.imageUrl ? banner.imageUrl : null,
        tablet_banner_image_url: banner.tabletImageUrl ? banner.tabletImageUrl : null,
        mobile_banner_image_url: banner.mobileImageUrl ? banner.mobileImageUrl : null,

        video_type: banner.videoType ? banner.videoType : null,
        local_video_url: banner.localVideoUrl ? banner.localVideoUrl : null,
        local_video_id: banner.localVideoId ? banner.localVideoId : null,
    }

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/banners/update`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const deleteBanner = async (bannerId) => {
    const data = {
        id: bannerId
    }

    const encodedData = Kjur.encode(data)

    // delete method doesnt do request boyd, it should be params like get
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/banners/delete?token=${encodedData}`, 
        // {token:encodedData},
        axiosConfig 
    )
}