import React,{useState, useEffect, useContext} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../stores/contexts/userContext'
import {MdKeyboardArrowDown} from 'react-icons/md'
import {FaRegUserCircle} from 'react-icons/fa'
import {RiLogoutCircleRLine, RiMenu2Fill} from 'react-icons/ri'
import { Avatar, Dropdown, Drawer, Menu } from 'antd';
import { logout } from '../stores/api_calls/authentications';
import LeftSideMenu from '../navigation/LeftSideMenu';
import './login.scss'



const UserChips = () => {

    let navigate = useNavigate();
    const location = useLocation();

    const [userConfig ,setUserConfig] = useContext(UserContext)
    const [showLeftSideDrawer, setShowLeftSideDrawer] = useState(false);

    useEffect(() => {
        setShowLeftSideDrawer(false);
      }, [location.pathname]);

    const showDrawer = () => {
      setShowLeftSideDrawer(true);
    };
    const onClose = () => {
      setShowLeftSideDrawer(false);
    };

    const onLogout = () => {
        const signOut = logout()
        signOut.then((res)=>{
            if(res.data.success){
                setUserConfig({...userConfig,isLoggedIn:false  , userDetails:{}})
                navigate("../login", { replace: true })
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    const menu = (
        <div className='menu-container'>
            <div className='link-body' onClick={()=>navigate("../profile", { replace: true })}>
                <p>Profile</p>
                <FaRegUserCircle/>
            </div>
            <div className='link-body' onClick={onLogout}>
                <p>Logout</p>
                <RiLogoutCircleRLine/>
            </div>
        </div>
    )

    return (
        <React.Fragment>
        <div className="top-header">
            <RiMenu2Fill className='burger-menu-btn' onClick={() => showDrawer()} size={30} />
            <div className='chip-container'>
                <Dropdown
                    overlay={menu}
                    placement='bottomRight'
                    arrow
                >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center'
                        }}
                    >
                        <Avatar className='avatar' >{userConfig.userDetails.user_first_name.substring(0,1)}</Avatar>
                        <p style={{margin:0}}>{userConfig.userDetails.user_first_name} {userConfig.userDetails.user_last_name}</p>
                        <MdKeyboardArrowDown/>
                    </div>
                </Dropdown>
            </div>
            
        </div>
        <Drawer
            title=""
            placement={'left'}
            closable={true}
            onClose={onClose}
            visible={showLeftSideDrawer}
            key={'left'}
            width="300"
        >
            <LeftSideMenu />
        </Drawer>
        </React.Fragment>
    )
}

export default UserChips
