import axios from "axios";
import Kjur from "../utils/jwt";
import moment from "moment";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchRentalProducts = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchRentalCarDetails = async (id) => {
  const obj = { id };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/product-catalog/rental/show?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchVerfiedRentalProducts = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental/verified-car-list?token=${encodedData}`,
    axiosConfig
  );
  // return await axios.get(
  //   `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental?token=${encodedData}`,
  //   axiosConfig
  // );
};

export const fetchUnverfiedRentalProducts = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental/unverified-car-list?token=${encodedData}`,
    axiosConfig
  );
};

export const addRentalProduct = async (payload, content, imagesIds) => {
  const obj = {
    product_image_id: imagesIds,
    product_rental_company_id: payload.companyId,
    car_details: {
      car_brand_id: payload.brandId,
      car_brand_model_id: payload.modelId,
      vehicle_type: payload.vehicleType,
      fuel_type: payload.fuelType,
      transmission: payload.transmission,
      classification: payload.classification,
    },
    registration_date: moment(payload.regDate).format("YYYY-MM-DD"),
    product_name: payload.productName,
    rental_type: payload.rentalType,
    product_short_term_details: {
      daily: payload.shortDaily,
      weekend: payload.shortWeekend,
      weekly: payload.shortWeekly,
      monthly: payload.shortMonthly,
    },
    product_long_term_details: {
      one_yr: payload.long1Yr,
      two_yr: payload.long2Yr,
      three_yr: payload.long3Yr,
      // four_yr: payload.long4Yr,
      // fifth_yr: payload.long5Yr,
    },
    product_hire_rental_details: {
      weekly: payload.hireWeekly,
      minimum_months: payload.hireMinMonths,
    },
    product_wedding_rental_details: {
      six_hours: payload.wedding6hr,
      twelve_hours: payload.wedding12hr,
    },
    product_description: content,
    product_pickup_location: {
      country: "SG",
      region_name: payload.region,
      city: payload.city,
      address: payload.street,
    },
    rental_requirements: {
      allowed_p_plate: payload.allowedPPlate,
      allowed_drive_to_malaysia: payload.allowedDriveToMalaysia,
      zero_deposit: payload.zeroDeposit,
      allowed_21yr_old: payload.allowed21yrOld,
      driving_experience: payload.drivingExperience,
      minimum_booking: payload.minimumBooking,
    },
    is_verified: payload.isVerified,
  };
  const encodedData = Kjur.encode(obj);

  console.log(obj, content, imagesIds);
  console.log(encodedData);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental/add`,
    { token: encodedData },
    axiosConfig
  );
};

export const editRentalProduct = async (payload, content, imagesIds) => {
  const obj = {
    product_rental_id: payload.productId,
    product_image_id: imagesIds,
    product_rental_company_id: payload.companyId,
    car_details: {
      car_brand_id: payload.brandId,
      car_brand_model_id: payload.modelId,
      vehicle_type: payload.vehicleType,
      fuel_type: payload.fuelType,
      transmission: payload.transmission,
      classification: payload.classification,
    },
    registration_date: moment(payload.regDate).format("YYYY-MM-DD"),
    product_name: payload.productName,
    rental_type: payload.rentalType,
    product_short_term_details: {
      daily: payload.shortDaily,
      weekend: payload.shortWeekend,
      weekly: payload.shortWeekly,
      monthly: payload.shortMonthly,
    },
    product_long_term_details: {
      one_yr: payload.long1Yr,
      two_yr: payload.long2Yr,
      three_yr: payload.long3Yr,
      // four_yr: payload.long4Yr,
      // fifth_yr: payload.long5Yr,
    },
    product_hire_rental_details: {
      weekly: payload.hireWeekly,
      minimum_months: payload.hireMinMonths,
    },
    product_wedding_rental_details: {
      six_hours: payload.wedding6hr,
      twelve_hours: payload.wedding12hr,
    },
    product_description: content,
    product_pickup_location: {
      country: "SG",
      region_name: payload.region,
      city: payload.city,
      address: payload.street,
    },
    rental_requirements: {
      allowed_p_plate: payload.allowedPPlate,
      allowed_drive_to_malaysia: payload.allowedDriveToMalaysia,
      zero_deposit: payload.zeroDeposit,
      allowed_21yr_old: payload.allowed21yrOld,
      driving_experience: payload.drivingExperience,
      minimum_booking: payload.minimumBooking,
    },
    is_verified: payload.isVerified,
  };
  const encodedData = Kjur.encode(obj);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteRentalProduct = async (rentalId) => {
  const data = {
    product_rental_id: rentalId,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/products/rental/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const fetchRentalClients = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    keyword: data.keyword,
    role_ids: data.roleIds,
  };

  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/rental_company?token=${encodedData}`,
    axiosConfig
  );
};

export const addCompany = async (payload) => {
  const obj = {
    user_first_name: payload.firstName,
    user_last_name: payload.lastName,
    company_name: payload.companyName,
    company_email: payload.emailValue,
    role_id: payload.roleId,
    company_website_url: payload.companyWebsite,
    company_location: {
      street: payload.companyStreet,
      country: "SG",
      region_name: payload.companyRegion,
      city: payload.companyCity,
    },
    cover_image_url: payload.imageUrl,
    company_hours: {
      weekdays: {
        opening_time: payload.weekDaysOpen,
        closing_time: payload.weekDaysClose,
      },
      weekends: {
        opening_time: payload.weekEndsOpen,
        closing_time: payload.weekEndsClose,
      },
    },
    company_contact_details: {
      phone_number: payload.phoneNumbers,
      email_add: payload.emailValue,
      email_add: payload.emailAdds,
    },
  };

  const encodedData = Kjur.encode(obj);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/rental_company/add`,
    { token: encodedData },
    axiosConfig
  );
};

export const editCompany = async (payload) => {
  const obj = {
    user_first_name: payload.firstName,
    user_last_name: payload.lastName,
    rental_company_id: payload.companyId,
    company_name: payload.companyName,
    company_email: payload.emailValue,
    role_id: payload.roleId,
    company_website_url: payload.companyWebsite,
    company_location: {
      street: payload.companyStreet,
      country: "SG",
      region_name: payload.companyRegion,
      city: payload.companyCity,
    },
    cover_image_url: payload.imageUrl,
    company_hours: {
      weekdays: {
        opening_time: payload.weekDaysOpen,
        closing_time: payload.weekDaysClose,
      },
      weekends: {
        opening_time: payload.weekEndsOpen,
        closing_time: payload.weekEndsClose,
      },
    },
    company_contact_details: {
      phone_number: payload.phoneNumbers,
      // email_add: payload.emailAdds,
    },
  };

  const encodedData = Kjur.encode(obj);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/rental_company/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteCompany = async (companyId) => {
  const data = {
    rental_company_id: companyId,
  };

  const encodedData = Kjur.encode(data);

  // delete method doesnt do request boyd, it should be params like get
  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/rental_company/delete?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};
