import { Checkbox, Input, Modal, Select } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { error, success } from "../../contants/snackbars";
import { addPlan, editPlan } from "../../stores/api_calls/packages";
import { generateInclusionLabel } from "../../stores/helpers/packageFormHelpers";
import "./styles.scss";
import { UserContext } from "../../stores/contexts/userContext";
import {
  addPlateNumber,
  editPlateNumber,
} from "../../stores/api_calls/plateNumbers";
import PhoneInput from "react-phone-input-2";
import {
  editAnnouncement,
  addAnnouncement,
} from "../../stores/api_calls/announcements";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw, convertToRaw} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';

const { TextArea } = Input;

const statuses = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const AnnouncementForm = ({
  visible,
  isEditing,
  data,
  onCancel,
  addSuccess,
  refetchLists,
}) => {
  const [userConfig, setUserConfig] = useContext(UserContext);
  const profile = userConfig.userDetails;
  const [requestLoading, setRequestLoading] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const [form, setForm] = useState({
    id: "",
    description: "",
    is_active: true,
  });

  useEffect(() => {
    if (isEditing) {
      setForm({
        id: data.id,
        description: data.description,
        is_active: data.is_active,
      });

      const contentState = convertFromRaw(data.description);
      const newContent = EditorState.createWithContent(contentState);
      setEditorState(newContent);
    } else {
      setForm({ description: "", is_active: null, id: null });
    }
  }, [isEditing, data]);

  const handleFieldChange = (name, e) => {
    if (name === "contactNumber") {
      setForm({ ...form, [name]: e });
    } else {
      setForm({ ...form, [name]: e.target.value });
    }
  };

  const onSubmit = () => {
    setRequestLoading(true);

    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const transformedData = form;
    transformedData.description = JSON.parse(rawDraftContentState);

    const createAnnouncement = addAnnouncement(transformedData);

    createAnnouncement
      .then((res) => {
        if (res.data.success) {
          onCancel();
          success("Announcement successfully added");
          refetchLists();
          setRequestLoading(false);
          resetForm();
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onUpdateAnnouncement = () => {
    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const transformedData = form;
    transformedData.description = JSON.parse(rawDraftContentState);

    const updateAnnouncement = editAnnouncement(transformedData);

    updateAnnouncement
      .then((res) => {
        if (res.data.success) {
          refetchLists();
          success("Announcement updated successfully");
          onCancel();
          setRequestLoading(false);
          resetForm();
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const resetForm = () => {
    setForm({ description: "", is_active: null });
  };
  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEditing ? "Edit" : "Add"} Announcement`}
        visible={visible}
        onOk={isEditing ? onUpdateAnnouncement : onSubmit}
        onCancel={onCancel}
        okText={`${isEditing ? "Save" : "Submit"}`}
      >
        <div className="form-fields">
          <p className="form-field-label">
            Description <span>*</span> :{" "}
          </p>
          {/* <TextArea
            rows={4}
            value={form.description}
            placeholder="Type Description"
            onChange={(text) => handleFieldChange("description", text)}
          /> */}

          <div className="editor-container">
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                inline: { inDropdown: false },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
              }}
            />
          </div>
        </div>

        <div className="form-fields">
          <p className="form-field-label">
            Status <span>*</span> :{" "}
          </p>
          <Select
            value={form.is_active}
            onChange={(value) => setForm({ ...form, is_active: value })}
            style={{ width: "100%" }}
            placeholder="Select status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {statuses.map((el, i) => (
              <Select.Option key={i} value={el.value}>
                {`${el.label}`}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default AnnouncementForm;
