import { Checkbox, Col, Row, Input, Select } from 'antd'
import React from 'react'
import './rentals.scss'
import { rentalDrivingExperience, rentalMinBooking } from '../../contants/variables'

const RentalRequirements = (props) => {

    const {form , onCheckboxChange, handleFieldChange, handleDropdownChange} = props

    return (
        <Row gutter={[10,10]}>
            <Col span={6}>
                <div className='form-fields' style={{margin:"20px 0px 0"}}>
                    <Checkbox
                        valuePropName="checked"
                        checked={form.allowedPPlate}
                        onChange={(e)=>onCheckboxChange('allowedPPlate',e)}
                    >
                        P-Plate Riders Allowed
                    </Checkbox>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields' style={{margin:"20px 0px 0"}}>
                    <Checkbox
                        valuePropName="checked"
                        checked={form.allowedDriveToMalaysia}
                        onChange={(e)=>onCheckboxChange('allowedDriveToMalaysia',e)}
                    >
                        Ride to Malaysia
                    </Checkbox>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields' style={{margin:"20px 0px 0"}}>
                    <Checkbox
                        valuePropName="checked"
                        checked={form.zeroDeposit}
                        onChange={(e)=>onCheckboxChange('zeroDeposit',e)}
                    >
                        S$ 0 Deposit
                    </Checkbox>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields' style={{margin:"20px 0px 0"}}>
                    <Checkbox
                        valuePropName="checked"
                        checked={form.allowed21yrOld}
                        onChange={(e)=>onCheckboxChange('allowed21yrOld',e)}
                    >
                        Above 18-Year Old Allowed
                    </Checkbox>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Riding Experience  : </p>
                    <Select
                        showSearch
                        value = {form.drivingExperience || undefined}
                        onChange={(value)=>handleDropdownChange('drivingExperience' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        // optionFilterProp="children"
                        // filterOption={(input, option) =>
                        // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                        // filterSort={(optionA, optionB) =>
                        // optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                    >
                        {rentalDrivingExperience.map((exp,i)=>
                            <Select.Option key={i}  value={exp}>{exp} Year</Select.Option>
                        )}
                    </Select>
                    
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Minimum Booking  : </p>
                    <Select
                        showSearch
                        value = {form.minimumBooking || undefined}
                        onChange={(value)=>handleDropdownChange('minimumBooking' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        
                    >
                        {rentalMinBooking.map((day,i)=>
                            <Select.Option key={i}  value={day}>{day}</Select.Option>
                        )}
                    </Select>
                    
                </div>
            </Col>
        </Row>
    )
}

export default RentalRequirements