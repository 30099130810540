import React, { useEffect, useState, useContext } from "react";
import "./login.scss";
import logo from "../../assets/images/logo.png";
import { Button, Form, Input } from "antd";
import { BiUser, BiLockAlt } from "react-icons/bi";
import {
  CancelButton,
  FormPrimaryButton,
  LoadingButton,
  PrimaryButton,
} from "../custom_components/customButton";
import { useNavigate } from "react-router-dom";
import { login, verifyOTP } from "../stores/api_calls/authentications";
import { UserContext } from "../stores/contexts/userContext";
import Kjur from "../stores/utils/jwt";
import { colorScheme } from "../contants/variables";

const Login = () => {
  let navigate = useNavigate();
  const [form, setForm] = useState({});
  const [step, setStep] = useState(0);
  const [userConfig, setUserConfig] = useContext(UserContext);
  const [callFailed, setCallFailed] = useState({ isError: false, message: "" });
  const [callLoading, setCallLoading] = useState(false);

  const sendCredentials = (formObject) => {
    setCallLoading(true);
    const fetchLogin = login(formObject);
    fetchLogin
      .then((res) => {
        if (res.data.success) {
          setStep(1);
          setForm(formObject);
          setCallFailed({ ...callFailed, isError: false, message: "" });
          setCallLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setCallFailed({
          ...callFailed,
          isError: true,
          message: error.response.data.server_response,
        });
        setCallLoading(false);
      });
  };

  useEffect(() => {
    if (step !== null) {
      setCallFailed({ ...callFailed, isError: false, message: "" });
    }
  }, [step]);

  const onLogin = (values) => {
    sendCredentials(values);
  };

  const onResend = () => {
    sendCredentials(form);
  };

  const onResendViaEmail = () => {
    sendCredentials({ ...form, useEmail: true });
  };

  const onSubmitOTP = (values) => {
    setCallLoading(true);
    const data = {
      username: form.username,
      otp: values.otp,
    };
    const onVerify = verifyOTP(data);
    onVerify
      .then((res) => {
        if (res.data.success) {
          const parsedData = Kjur.decode(res.data.token);
          setUserConfig({
            ...userConfig,
            isLoggedIn: true,
            userDetails: parsedData,
          });
          setCallFailed({ ...callFailed, isError: false, message: "" });
          setCallLoading(false);
          navigate("../dashboard", { replace: true });
          window.location.reload();
        }
      })
      .catch((error) => {
        setCallFailed({
          ...callFailed,
          isError: true,
          message: error.response.data.server_response,
        });
        setCallLoading(false);
      });
  };

  return (
    <div className="body-container">
      <div className="login-container">
        <div className="logo-container">
          <img src={logo} />
        </div>
        <div className="form-container">
          {/* <p>ENV VARIABLE : {process.env.REACT_APP_API_BASE_URL ?? "NO ENV FOUND"}</p> */}
          {step === 0 ? (
            <Form onFinish={onLogin}>
              <Form.Item
                name="username"
                rules={[{ required: true, message: "Please input your Email" }]}
              >
                <Input prefix={<BiUser />} placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  prefix={<BiLockAlt />}
                  type="password"
                  placeholder="Password"
                  size="large"
                />
              </Form.Item>

              {callFailed.isError ? (
                <p className="error-text">{callFailed.message}</p>
              ) : null}

              <div className="forgot-container">
                <p
                  className="forgot-password-hlink"
                  onClick={() =>
                    navigate("../forgot-password", { replace: true })
                  }
                >
                  Forgot password?
                </p>
              </div>

              <Form.Item>
                {callLoading ? (
                  <LoadingButton />
                ) : (
                  <FormPrimaryButton
                    color={colorScheme.primary}
                    label="LOG IN"
                  />
                )}
              </Form.Item>
            </Form>
          ) : (
            <Form onFinish={onSubmitOTP}>
              <p className="otp-header">
                We have sent an <strong>OTP</strong> to your registered email. 
                <br /> Note : <strong>OTP</strong> will expire in 2 minutes
              </p>
              <div className="otp-form-container">
                <Form.Item
                  name="otp"
                  rules={[{ required: true, message: "Please input your OTP" }]}
                  style={{ flex: 1 }}
                >
                  <Input
                    // prefix={<BiUser/>}
                    placeholder="OTP"
                    size="large"
                  />
                </Form.Item>

                <div style={{ width: "100px" }}>
                  <PrimaryButton
                    color={colorScheme.primary}
                    label="Resend"
                    onClick={onResend}
                  />
                </div>
              </div>

              {callFailed.isError ? (
                <p className="error-text">{callFailed.message}</p>
              ) : null}

              <Form.Item>
                {callLoading ? (
                  <LoadingButton />
                ) : (
                  <FormPrimaryButton
                    color={colorScheme.primary}
                    label="Verify OTP"
                  />
                )}
              </Form.Item>
              {/* <div style={{ marginBottom: "24px" }}>
                <center>
                  <a onClick={onResendViaEmail}>Resend OTP in email</a>
                </center>
              </div> */}
              <CancelButton
                bgColor={colorScheme.offwhite}
                textColor={colorScheme.black}
                label="Go Back"
                onClick={() => setStep(0)}
              />
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
