import { Col, Radio, Row } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { success, error } from "../../contants/snackbars";
import { colorScheme } from "../../contants/variables";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import {
  addCarProduct,
  savedCarProduct,
  updateCarProduct,
} from "../../stores/api_calls/carlists";
import { uploadImage } from "../../stores/api_calls/upload";
import { MobileView, WebView } from "./Views";

const PreviewCar = (props) => {
  const { isEdit, form, selectedFiles } = props;
  const [view, setView] = useState(0);
  const [requestLoading, setRequestLoading] = useState(false);
  const [localImageUrls, setLocalImageUrls] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedFiles.length) {
      const localImg = selectedFiles.map((file) => URL.createObjectURL(file));
      setLocalImageUrls(localImg);
    }
  }, [selectedFiles]);

  const onViewChange = (e) => {
    setView(e.target.value);
  };

  const onSubmit = () => {
    setRequestLoading(true);
    const imagesIds = isEdit
      ? form.imageUrls.map((img) => img._id["$oid"])
      : [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const waterMark = true;
        const directSeller = form.direct_seller;
        console.log(directSeller);
        const onUpload = uploadImage(file, index + 1, waterMark, directSeller);

        onUpload
          .then((res) => {
            if (res.data.success) {
              imagesIds.push(res.data.data._id);
              // console.log('images',imagesIds)
            }

            if (
              imagesIds.length - form.imageUrls.length ===
              selectedFiles.length
            ) {
              const car = isEdit
                ? updateCarProduct(form, imagesIds)
                : addCarProduct(form, imagesIds);

              car
                .then((res) => {
                  success(`Bike ${isEdit ? "updated" : "added"} Successfully`);
                  setRequestLoading(false);
                  navigate("../bikes/verified", { replace: true });
                })
                .catch((e) => {
                  error(e.response.data.server_response);
                  setRequestLoading(false);
                });
            }
          })
          .catch((e) => {
            error(e.response.data.server_response);
            setRequestLoading(false);
          });
      });
    } else {
      const car = isEdit
        ? updateCarProduct(form, imagesIds)
        : addCarProduct(form, imagesIds);
      car
        .then((res) => {
          // console.log(res.data);
          success(`Bike ${isEdit ? "updated" : "added"} Successfully`);
          setRequestLoading(false);
          navigate("../bikes/verified", { replace: true });
        })
        .catch((e) => {
          console.log(e);
          error(e.response.data.server_response);
          setRequestLoading(false);
        });
    }
  };

  // const onSaved = () =>{
  //     const imagesIds = []

  //     selectedFiles.forEach((file,index)=>{
  //         const onUpload = uploadImage(file)

  //         onUpload.then((res)=>{
  //             console.log(res.data)
  //             if(res.data.success){
  //                 imagesIds.push(res.data.data._id)
  //             }

  //             if(imagesIds.length===selectedFiles.length){
  //                 // console.log('images' ,imagesIds.length , imagesIds)
  //                 const car = savedCarProduct(form , imagesIds)
  //                 car.then((res)=>{
  //                     console.log('create ad', res.data)
  //                 }).catch(e=>console.log(e))
  //             }
  //         }).catch(e=>console.log(e))
  //     })
  // }

  return (
    <div>
      <Row>
        <Col span={8}>
          <div className="preview-text-container">
            <p className="preview-header">
              Choose the device you want to preview
            </p>
            <Radio.Group onChange={onViewChange} value={view}>
              <Radio value={0}>Web Application</Radio>
              <Radio value={1}>Mobile Application</Radio>
            </Radio.Group>

            <p className="preview-note">
              If you are happy with the result kindly click button below.
            </p>

            <div className="button-container">
              {/* <PrimaryButton className="button" color={colorScheme.secondary} label="Save as Draft" onClick={onSaved}/> */}
              {requestLoading ? (
                <LoadingButton />
              ) : (
                <PrimaryButton
                  className="button"
                  color={colorScheme.primary}
                  label={isEdit ? "Update this Ad" : "Create this Ad"}
                  onClick={onSubmit}
                />
              )}
            </div>
          </div>
        </Col>

        <Col span={16}>
          <div className="preview-container">
            <p style={{ marginBottom: "10px" }}>
              Hover on the screen and scroll to view contents
            </p>
            {!view ? (
              <WebView form={form} localImageUrls={localImageUrls} />
            ) : (
              <MobileView form={form} localImageUrls={localImageUrls} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewCar;
