import { Input , Row , Col, Skeleton } from 'antd';
import React , {useState , useEffect} from 'react';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import './package.scss';
import {MdAddCircle} from 'react-icons/md';
import {FiSearch} from 'react-icons/fi'
import { fetchPackages } from '../stores/api_calls/carlists';
import { RiDeleteBin2Fill , RiEdit2Fill , RiCloseCircleFill} from 'react-icons/ri';
import {FaCheckCircle} from 'react-icons/fa';
import PackageForm from '../forms/packages/PackageForm';
import InclusionForm from '../forms/packages/InclusionForm';
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { colorScheme } from '../contants/variables';
import { error, success } from '../contants/snackbars';
import { deletePlan, fetchInclusions, fetchPlans } from '../stores/api_calls/packages';

const PackagesIndex = (props) => {

    const [packages , setPackages] = useState([])
    const [inclusions , setInclusions] = useState([])
    const [fetchLoading , setFetchLoading] = useState(false)
    const [showModal , setShowModal] = useState({isEdit:false , showDelete:false, showPackageForm:false , showInclusionForm:false , data:{}})
    const [searchText , setSearchText] = useState("")
    const [filteredPackage , setFilteredPackage] = useState([])

    useEffect(()=>{
        setFetchLoading(true)
        const packages = fetchPlans()
        packages.then((res)=>{
            // console.log(res.data.data)
            if(res.data.success){
                setPackages(res.data.data)
                setFilteredPackage(res.data.data)

                const getInclusions = fetchInclusions()
                getInclusions.then((res)=>{
                    if(res.data.success){
                        // console.log(res.data.data)
                        setInclusions(res.data.data)
                        setFetchLoading(false)
                    }
                }).catch((e)=>{
                    console.log(e)
                })
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    const refetchPackage = () => {
        setFetchLoading(true)
        const packages = fetchPlans()
        packages.then((res)=>{
            if(res.data.success){
                setPackages(res.data.data)
                setFilteredPackage(res.data.data)
                setFetchLoading(false)
            }
        }).catch((e)=>{
            console.log(e)
        })
    }

    useEffect(()=>{
        if(searchText){
            const filteredData = packages.filter(item=>item.package_name.toLowerCase().includes(searchText.toLowerCase()))
            setFilteredPackage(filteredData)
        }
    },[searchText])

    const onDeleteSubmit = () => {
        const removePackage = deletePlan(showModal.data.id)
        removePackage.then((res)=>{
            if(res.data.success){
                success("Package deleted successfully")
                refetchPackage()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
        setShowModal({...showModal , showDelete:false })
    }

    const handleFieldChange = (name , e) => {
        setSearchText(e.target.value)
    }

    const onAdd = () => {
        setShowModal({...showModal , showPackageForm:true , isEdit:false})
    }

    const onEdit = (item) => {
        console.log(item);
        setShowModal({...showModal , showPackageForm:true , isEdit:true , data:item})
    }

    const onDelete = (item) => {
        setShowModal({...showModal , showDelete:true , data:item })
    }

    const onOpenInclusionForm = () => {
        setShowModal({...showModal , showInclusionForm:true})
    }

    return (
        <div className='package-container'>
            <div className='package-header'>
                <div className='search-field-div'>
                    <Input
                        prefix={<FiSearch/>}
                        size='large'
                        placeholder='Search Record'
                        value={searchText}
                        onChange={text=>handleFieldChange('search' , text)}
                    />
                </div>
                <div className='btn-div'>
                    <PrimaryButton
                        Icon={<MdAddCircle/>}
                        label="Add New Package"
                        color={colorScheme.primary}
                        onClick={()=>onAdd()}
                    />
                </div>
                <div className='btn-div'>
                    <PrimaryButton
                        Icon={<MdAddCircle/>}
                        label="Manage Inclusions"
                        color={colorScheme.primary}
                        onClick={()=>onOpenInclusionForm()}
                    />
                </div>
            </div>

            <div className='packages-card-body'>
                <Row gutter={[12,12]}>
                    {fetchLoading?
                        [...Array(4)].map((_,i)=>
                            <Col key={i} span={6}>
                                <div className='package-card-container' style={{padding:"20px"}}>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            </Col>
                        )
                    :!fetchLoading && filteredPackage.length?
                        filteredPackage.map((item,idx)=>
                        <Col span={7} key={idx}>
                                <div className='package-card-container'>
                                    <div className='package-card-header'>
                                        <p>{item.package_name}</p>
                                        <div className='icon-buttons'>
                                            <IconButton Icon={<RiEdit2Fill/>} onClick={()=>onEdit(item)} />
                                            {/* <IconButton Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(item)}/> */}
                                        </div>
                                    </div>
                                    {/* <div className='package-price-container'>
                                        <p>SGD {item.package_cost} <span>until it sold</span></p>
                                    </div> */}
                                    <div className='package-inclusion-container'>
                                        <p>Inclusions : </p>
                                        <div className="inclusion-div" style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>Price </div>
                                            <div>{item.package_cost == 0 ? 'Free' : item.package_cost}</div>
                                        </div>
                                        <div className="inclusion-div" style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>Number of photos </div>
                                            <div>{item.number_of_photo}</div>
                                        </div>
                                        <div className="inclusion-div" style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>Duration of Ad</div>
                                            <div>{item.during_of_ad} Weeks</div>
                                        </div>
                                        {inclusions.length?
                                            inclusions.map((inclusion , i) => 
                                                <div className='inclusion-div' key={i}>
                                                    <div>
                                                        {item.package_inclusions.includes(inclusion.code)?
                                                            <FaCheckCircle style={{color:colorScheme.green}}/>
                                                            :<RiCloseCircleFill style={{color:colorScheme.red}}/>
                                                        }
                                                    </div>
                                                    <p>{inclusion.name}</p>
                                                </div>
                                            )
                                        :null}
                                    </div>
                                </div>
                        </Col>
                    )
                    :<p>Fetch Failed</p>}
                </Row>
            </div>
            
            <PackageForm
                isEdit={showModal.isEdit}
                inclusions={inclusions}
                refetchPackage = {refetchPackage}
                visible={showModal.showPackageForm}
                onCancel={()=>{
                    setShowModal({...showModal , isEdit:false, showPackageForm:false , data:{}})
                }}
                data={showModal.data}
            />
            <InclusionForm
                inclusions={inclusions}
                setInclusions={setInclusions}
                visible={showModal.showInclusionForm}
                onCancel={()=>setShowModal({...showModal , showInclusionForm:false , data:{}})}
            />
            <CustomDeleteModal
                title="Delete Package"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.package_name} Package` , type:"package"}}
            />

        </div>
    )
}

export default PackagesIndex
