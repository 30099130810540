export const validateProductForm = (data) => {
  const requiredFields = [
    "carName",
    "carBrand",
    "carModel",
    // "askingPrice",
 
  ];

  var validFieldCount = 0;

  requiredFields.forEach((field) => {
    if (data[field] !== "") {
      validFieldCount = validFieldCount + 1;
    }
  });

  if (validFieldCount === requiredFields.length) {
    return true;
  } else {
    return false;
  }
};

export const validateNewProductForm = (data) => {
  const requiredFields = [
    "carName",
    "carBrand",
    "carModel",
    "classification",
    "new_condition",
    "vehicleType", 
  ];

  var validFieldCount = 0;

  requiredFields.forEach((field) => {
    if (data[field] !== "") {
      validFieldCount = validFieldCount + 1;
    }
  });

  if (validFieldCount === requiredFields.length) {
    return true;
  } else {
    return false;
  }
};

export const validateUpload = (selectedFiles, currentImages) => {
  // console.log(selectedFiles);
  // console.log(currentImages);
  const selectedTotal = selectedFiles.reduce((n, { size }) => n + size, 0);
  const currentTotal = currentImages
    .map((el) => el.metadata)
    .reduce((n, { size }) => n + size, 0);

  const selectedTotalMB = selectedTotal / 1048576;
  const currentTotalMB = currentTotal / 1048576;

  const overAllTotal = selectedTotalMB + currentTotalMB;
  const overImageLength = selectedFiles.length + currentImages.length;

  // console.log('overALL' , overAllTotal.toFixed(2) , overImageLength)


  // console.log("overall total", overAllTotal);
  // console.log("overall image length", overImageLength);
  

  // if (overAllTotal <= 10 && overImageLength >= 1 && overImageLength <= 9) {
  if ( overImageLength >= 1 && overImageLength <= 9) {
    return true;
  } else {
    return false;
  }
};

export const validateLimitUpload = (selectedFiles, currentImages) => {

  const overImageLength = selectedFiles.length + currentImages.length;

  if (overImageLength < 9) {
    return true;
  } else {
    return false;
  }
  
};