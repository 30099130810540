import React , {useState , useEffect, useContext} from 'react';
import './dashboard.scss'
import { Col, Row, Spin, Table } from 'antd'
import VerifiedCars from './cards/VerifiedCars';
import VerifiedCommercial from './cards/VerifiedCommercial';
import UnverifiedCars from './cards/Unverified';
import UnverifiedCommercial from './cards/UnverifiedCommercial';
import SoldCars from './cards/SoldCars';
import ActiveCars from './cards/Active';
import RentalUnverified from './cards/RentalUnverified';
import VerifiedBidCars from './cards/VerifiedBidCars';
import UnverifiedBidCars from './cards/UnverifiedBidCars';
// import SoldBidCars from './cards/SoldBidCars';
import TotalCarList from './cards/TotalCarlist';
// import TotalBanner from './cards/TotalBanner';
import TotalDealer from './cards/TotalDealer';
import TotalDirectSeller from './cards/TotalDirectSeller';
import TotalSubscribeEmail from './cards/TotalSubscribeEmail';
import TotalUnsubscribeEmail from './cards/TotalUnsubscribeEmail';
import DraftCars from './cards/DraftCars';
import { fetchSoldCars, fetchVerifiedCars } from '../stores/api_calls/carlists';
import { AccessContext } from '../stores/contexts/accessContext';
// import { fetchPermissions, fetchRoles } from '../stores/api_calls/users';


const DashboardIndex = (props) => {

    const [verifiedList , setVerifiedList] = useState({isLoading:false , data:[]})
    const [soldList , setSoldList] = useState({isLoading:false , data:[]})
    const [roles , permissions , setRoles , setPermissions] = useContext(AccessContext)

    useEffect(()=>{
        getVerified()
        getSold()
    },[])

    const getVerified = () => {
        const data = {
            page:1,
            limit:10,
            keyword:""
        }
        setVerifiedList({...verifiedList , isLoading:true})
        const verified = fetchVerifiedCars(data)
        verified.then((res)=>{
            if(res.data.success){
                console.log(res.data.data)
                setVerifiedList({...verifiedList , isLoading:false , data:res.data.data})
            }
        }).catch((e)=>{
            setVerifiedList({...verifiedList , isLoading:false})
        })
    }

    const getSold = () => {
        const data = {
            page:1,
            limit:10,
            keyword:""
        }
        setSoldList({...soldList , isLoading:true})
        const sold = fetchSoldCars(data)
        sold.then((res)=>{
            if(res.data.success){
                // console.log(res.data.data)
                setSoldList({...soldList , isLoading:false , data:res.data.data})
            }
        }).catch((e)=>{
            setSoldList({...soldList , isLoading:false})
        })
    }

    const recentAddColumn = [
        {
            title: 'Bike Name',
            dataIndex: 'bikeName',
            // sorter: true,
            render: (text ,record) => `${record.product_name}`,
        },
        {
            title: 'Brand Model',
            dataIndex: 'brand',
            render: (text ,record) => `${record?.product_brand_model?.model_name}`,
        },
        {
            title: 'Mileage',
            dataIndex: 'transmission',
            render: (text ,record) => `${record.product_mileage.toLocaleString()} mpg`,
        },
        {
            title: 'Engine Cap',
            dataIndex: 'engine',
            render: (text ,record) => `${record.product_cc.toLocaleString()} cc`,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (text ,record) => `S$ ${record.product_price.toLocaleString()}`,
        },
    ];

    const recentSoldColumn = [
        {
            title: 'Bike Name',
            dataIndex: 'bikeName',
            // sorter: true,
            render: (text ,record) => `${record.product_name}`,
        },
        {
            title: 'Brand Model',
            dataIndex: 'brand',
            render: (text ,record) => `${record.product_brand_model.model_name}`,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            render: (text ,record) => `S$ ${record.product_price.toLocaleString()}`,
        },
    ]

    return (
        <div className='container'>
            <Row>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <VerifiedCars/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <UnverifiedCars/>
                    </div>
                </Col>
                <Col span={4} className='car-lists-card-container'>
                    <div className='card-body'>
                        <SoldCars/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <ActiveCars/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <RentalUnverified/>
                    </div>
                </Col>
            </Row>

            <Row>
                {/* <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <VerifiedCommercial/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <UnverifiedCommercial/>
                    </div>
                </Col> */}
                
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <VerifiedBidCars/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <UnverifiedBidCars/>
                    </div>
                </Col>
                <Col span={4} className='car-lists-card-container'>
                    <div className='card-body'>
                        <TotalCarList/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <TotalDealer/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <TotalDirectSeller/>
                    </div>
                </Col>
            </Row>

            <Row>
                
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <TotalSubscribeEmail/>
                    </div>
                </Col>
                <Col span={5} className='car-lists-card-container'>
                    <div className='card-body'>
                        <TotalUnsubscribeEmail/>
                    </div>
                </Col>
                <Col span={4} className='car-lists-card-container'>
                    <div className='card-body'>
                        <DraftCars/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={14} className='chart-lists-card-container '>
                    <div className='chart-body'>
                        <p className='header'>Recently Added Bikes</p>
                        <Table
                            columns={recentAddColumn}
                            rowKey={record => record._id}
                            dataSource={verifiedList.data}
                            loading={{indicator:<Spin size='large'/> , spinning:verifiedList.isLoading}}
                            pagination={false}
                            bordered
                            sticky
                        />
                    </div>
                </Col>
                <Col span={10} className='chart-lists-card-container'>
                    <div className='chart-body'>
                        <p className='header'>Recently Sold Bikes</p>
                        <Table
                            columns={recentSoldColumn}
                            rowKey={record => record._id}
                            dataSource={soldList.data}
                            loading={{indicator:<Spin size='large'/> , spinning:soldList.isLoading}}
                            pagination={false}
                            bordered
                            sticky
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default DashboardIndex
