//import liraries
import { Input, Row, Col, Divider, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import "./styles.scss";
import { MdAddCircle, MdLocationPin } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import {
  RiEye2Line,
  RiDeleteBin2Fill,
  RiEdit2Fill,
  RiPinDistanceLine,
  RiMapPin2Fill,
  RiMapPin3Line,
} from "react-icons/ri";
import { colorScheme } from "../contants/variables";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { useNavigate } from "react-router-dom";
import {
  deleteCarWorkShop,
  fetchAccessories,
  fetchCarWorkshops,
} from "../stores/api_calls/car-workshop";
import CategoryForm from "../forms/car-workshops/CategoryForm";
import BrandForm from "../forms/car-workshops/BrandForm";
import Accessories from "./Accessories";
import AccessoryForm from "../forms/car-workshops/AccessoryForm";

const CarWorkShops = () => {
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
    numOfArticlePerPage: 20,
    sectionId: "0",
  });

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 36,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [tableData, setTableData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [accessories, setAccessories] = useState({});
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState({
    showPreview: false,
    showDelete: false,
    showCategory: false,
    data: {},
  });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const onEnter = () => {
    fetchData({ ...tablePagination, keyword: searchText });
  };

  const handleFieldChange = (key, e) => {
    setSearchText(e.target.value);
  };

  const onAdd = () => {
    navigate("../bike-workshops/create", { replace: true });
  };

  const onPreview = () => {};
  const onEdit = () => {};

  const onDeleteSubmit = () => {
    // const filteredData = workshops.filter(
    //   (item) => item.id !== showModal.data.id
    // );
    // setWorkshops(filteredData);
    // setShowModal({ ...showModal, data: {}, showDelete: false });
  };

  useEffect(() => {
    fetchData(tablePagination);
  }, []);

  const fetchData = (data) => {
    setIsLoading(true);
    const carWorkshops = fetchCarWorkshops(data);
    carWorkshops
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          console.log(res.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    console.log(record);
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKeys(keys);
    setIsExpanded(expanded);
  };

  const handleTableChange = (pagination) => {
    setTablePagination(pagination);
  };

  const handleDeleteWorkshop = (id) => {
    deleteCarWorkShop(id);
    fetchData(tablePagination);
  };

  const handleAddAccessory = (record) => {
  setIsEdit(false);
  setShowModal({
    ...showModal,
    showAccessoryForm: true,
    type: "Accessory",
    data: record,
  });
};

  useEffect(() => {
    if (expandedRowKeys.length) {
      getAccessories();
    }
  }, [expandedRowKeys]);

  const getAccessories = () => {
    setAccessories({ ...accessories, isLoading: true });
    const onGetAccessories = fetchAccessories(expandedRowKeys[0]);

    onGetAccessories
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setAccessories({
            ...accessories,
            isLoading: false,
            data: res.data.data,
            total: res.data.total_count,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setModels({ ...accessories, isLoading: false });
      });
  };

  const columns = [
    {
      title: "Bike workshop",
      dataIndex: "bike_workshop_name",
      // sorter: true,
      render: (text, record) => (
        <div className="user-name-cont">
          <span>{record.car_workshop_name}</span>
        </div>
      ),
      // width: '20%',
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (text, record) => <span>{record.car_workshop_address}</span>,
      // width: '30%',
    },
    // {
    //   title: "Contact Number",
    //   dataIndex: "contact_numbers",
    //   render: (text, record) =>
    //     record?.contact_numbers.length
    //       ? `${record?.contact_numbers?.map((el) => el.number)}`
    //       : null,
    // },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => (
        <div className="user-role-div">
          <p>{record.email}</p>
        </div>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      width: "10%",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
          className="icon-button edit"
          tootTipTitle="Edit User"
          Icon={<RiEdit2Fill />}
          onClick={() => {}}
        />
          <IconButton
            className="icon-button add"
            tootTipTitle="Add Accessory"
            Icon={<MdAddCircle />}
            onClick={() => handleAddAccessory(record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Bike Workshop"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => handleDeleteWorkshop(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="article-container">
      <div className="article-header">
        <div className="article-filter">
          <div className="search-field-div">
            {/* <Input
              prefix={<FiSearch />}
              size="large"
              placeholder="Search Workshop"
              value={searchText}
              onChange={(text) => handleFieldChange("search", text)}
              onKeyPress={onEnter}
            /> */}
          </div>
          <div className="search-field-div"></div>
        </div>
        <div className="article-btn">
          <div className="btn-div">
            <PrimaryButton
              label="Manage Categories"
              color={colorScheme.primary}
              onClick={() => setShowModal({ ...showModal, showCategory: true })}
            />
          </div>
          <div className="btn-div">
            <PrimaryButton
              label="Manage Brands"
              color={colorScheme.primary}
              onClick={() => setShowModal({ ...showModal, showBrand: true })}
            />
          </div>
          <div className="btn-div">
            <PrimaryButton
              Icon={<MdAddCircle />}
              label="Add Workshop"
              color={colorScheme.primary}
              onClick={() => onAdd()}
            />
          </div>
        </div>
      </div>

      <div className="table-div">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          expandedRowKeys={expandedRowKeys}
          onExpand={onTableRowExpand}
          expandable={{
            expandedRowRender: (record) =>
              isExpanded ? (
                <div style={{ margin: 0 }}>
                  <Accessories
                    accessories={accessories}
                    fetchAccessories={getAccessories}
                  />
                </div>
              ) : null,
            rowExpandable: (record) => record.number_of_models !== 0,
          }}
          dataSource={tableData}
          pagination={tablePagination}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <CategoryForm
        visible={showModal.showCategory}
        onCancel={() =>
          setShowModal({ ...showModal, showCategory: false, data: {} })
        }
        sections={categories}
        setSections={setCategories}
      />

      <BrandForm
        visible={showModal.showBrand}
        onCancel={() =>
          setShowModal({ ...showModal, showBrand: false, data: {} })
        }
        sections={brands}
        setSections={setBrands}
      />

      <AccessoryForm
        visible={showModal.showAccessoryForm}
        onCancel={() =>
          setShowModal({ ...showModal, showAccessoryForm: false, data: {} })
        }
        isEdit={isEdit}
        data={showModal.data}
      />

      <CustomDeleteModal
        title="Delete Bike Workshop"
        visible={showModal.showDelete}
        onSubmit={onDeleteSubmit}
        onCancel={() =>
          setShowModal({ ...showModal, showDelete: false, data: {} })
        }
        data={{ itemName: `${showModal.data.title}` }}
      />
    </div>
  );
};

export default CarWorkShops;
