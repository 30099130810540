import React , {useState , useEffect} from 'react';
import { fetchTotalRentalCars } from '../../stores/api_calls/dashboard';
import {MdCarRental} from 'react-icons/md'
import {AiOutlineArrowRight} from 'react-icons/ai'
import './card.scss'
import { useNavigate } from 'react-router-dom';

const ActiveCars = () => {

    const navigate = useNavigate()
    const [activeCars , setActiveCars] = useState()
    const [isLoading , setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        const getActiveCars = fetchTotalRentalCars()

        getActiveCars.then((res)=>{
            if(res.data){
                if(res.data.success){
                    setActiveCars(res.data.data)
                    setIsLoading(false)
                }
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    return (
        <div>
            <div className='active header-card'>
                <p className='header-text'>Rental <br/>Verified Bikes</p>
                <MdCarRental style={{width:"80px" , height:"80px"}}/>
            </div>
            <div className='active body-card'>
                <p className='active-text'>Total : {isLoading ? 'fetching' : !isLoading&&activeCars? activeCars.total_rental : null}</p>
                <div className='goIcon' onClick={()=>navigate("../rentals/verified", { replace: true })}>
                    <AiOutlineArrowRight/>
                </div>
            </div>
        </div>
    )
}

export default ActiveCars
