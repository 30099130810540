import {
    Input,
    Modal,
    Select,
    Space,
    DatePicker,
    Divider,
    Row,
    Col,
    Checkbox,
  } from "antd";
  import React, { useRef, useState, useEffect } from "react";
  import "./marketingemail.scss";
  import { error, success } from "../../contants/snackbars";
  import { addEmail, editEmail } from "../../stores/api_calls/marketing-email";
  import moment from "moment";
  import { validateEmailForm } from "../../stores/helpers/emailFormValidation";
  import { IconButton } from "../../custom_components/customButton";
  import {
    colorScheme,
  } from "../../contants/variables";
  
  const EmailForm = (props) => {
    const { isEdit, data, onCancel, visible, fetchData, tablePagination } = props;
    const [submitLoading, setSubmitLoading] = useState(false);

    const [form, setForm] = useState({
      email: "",
      latestCarSubscribe: true,
      isActive: true,
    });
  
    useEffect(() => {
      if (isEdit) {
        setForm({
          ...form,
          emailId: data._id,
          email: data.email,
          latestCarSubscribe: data.latest_car_subscribe,
          isActive: data.is_active,
        });
      }
    }, [isEdit]);
  
    const refetchLists = () => {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
        keyword: "",
        latest_car_subscribe: true,
      };
      fetchData(pagination);
    };
  
    const handleFieldChange = (name, e) => {
      setForm({ ...form, [name]: e.target.value });
    };

    const onCheckboxChange = (name, e) => {
        setForm({
          ...form,
          [name]: e.target.checked,
        });
    };
  
    const onClose = () => {
      setForm({
        ...form,
        email: "",
        latestCarSubscribe: true,
        isActive: true,
      });
      onCancel();
    };
  
    const onSubmit = () => {
        setSubmitLoading(true)
        
        var data = form;

        if(isEdit){

            const marketingEmail = editEmail(data)

            marketingEmail.then((res)=>{
                if(res.data.success){
                  console.log(res.data);
                    refetchLists();
                    success("Email Updated Successfully")
                    setSubmitLoading(false)
                    onClose()
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }else{

            const marketingEmail = addEmail(data)

            marketingEmail.then((res)=>{
              console.log(res);
                if(res.data.success){
                    refetchLists();
                    success("Email Added Successfully")
                    setSubmitLoading(false)
                    onClose()
                }
            }).catch((e)=>{
                error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
            })
        }
    }
  
    return (
      <div>
        <Modal
          className="formModal"
          title={`${isEdit ? "Edit" : "Create"} Email`}
          visible={visible}
          // width={700}
          onOk={onSubmit}
          okButtonProps={{ disabled: !validateEmailForm(form) }}
          onCancel={onClose}
          okText={`${isEdit ? "Save" : "Submit"}`}
          confirmLoading={submitLoading}
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className="form-container">
                <p className="form-label">
                  Email <span>*</span> :{" "}
                </p>
                <Input
                  value={form.email}
                  onChange={(text) => handleFieldChange("email", text)}
                  placeholder="Please Type Email"
                />
              </div>

              <div className="form-fields">
                    <Checkbox
                    valuePropName="checked"
                    checked={form.latestCarSubscribe}
                    onChange={(e) => onCheckboxChange("latestCarSubscribe", e)}
                    >
                    Latest Bike Subscribe
                    </Checkbox>
              </div>
              
              {/* <div className="form-fields">
                    <Checkbox
                    valuePropName="checked"
                    checked={form.isActive}
                    onChange={(e) => onCheckboxChange("isActive", e)}
                    >
                    Is Active
                    </Checkbox>
              </div> */}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };
  
  export default EmailForm;
  