import { Col, DatePicker, Input, Row, Select, Space } from 'antd'
import React from 'react'
import { fuelTypes, vehicleTypes } from '../../contants/variables'
import './rentals.scss'
import moment from 'moment';

const RentalDetails = (props) => {

    const {form , locations , brands , models , companies, handleDropdownChange , 
        handleFieldChange , onDateFieldChange, classifications} = props
    
    return (
        <Row gutter={[15,15]}>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Rental Company <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.companyId || undefined}
                        onChange={(value)=>handleDropdownChange('companyId' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {companies.data.map((el,i)=>
                            <Select.Option key={i} value={el._id}>
                                {`${el.company_name}`} 
                            </Select.Option>
                        )}
                    </Select>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Bike Name <span>*</span> : </p>
                    <Input
                        value={form.productName}
                        // prefix={<GoDeviceMobile/>}
                        onChange={text=>handleFieldChange('productName' , text)}
                    />
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Bike Brand <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.brandId || undefined}
                        onChange={(value)=>handleDropdownChange('brandId' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {brands.data.map((el,i)=>
                            <Select.Option key={i} value={el._id}>
                                {`${el.name}`} 
                            </Select.Option>
                        )}
                    </Select>
                </div>
            </Col>
            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Bike Model <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.modelId || undefined}
                        disabled={!models.data.length}
                        loading={models.loading && form.brandId}
                        onChange={(value)=>handleDropdownChange('modelId' , value)}
                        style={{ width: '100%' }}
                        placeholder={models.loading? `Fetching list of models` : !models.data.length ? `No model available in this brand` : `Search to Select`}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {models.data.map((el,i)=>
                            <Select.Option key={i} value={el._id}>
                                {`${el.name}`} 
                            </Select.Option>
                        )}
                    </Select>
                </div>
            </Col>

            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Registration Date <span>*</span> : </p>
                    <Space >
                        <div className='datepicker'>
                            <DatePicker
                                format="DD-MMM-YYYY"
                                style={{width:'100%'}}
                                value={moment(form.regDate)}
                                onChange={(date , dateString)=>onDateFieldChange('regDate' ,  dateString)}
                            />
                        </div>
                    </Space>
                </div>
            </Col>

            {/* <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Manufactured Year : </p>
                    <Input
                        value={form.productEdition}
                        // prefix={<GoDeviceMobile/>}
                        onChange={text=>handleFieldChange('productEdition' , text)}
                    />
                </div>
            </Col> */}

            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Transmission <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.transmission || undefined}
                        onChange={(value)=>handleDropdownChange('transmission' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        <Select.Option value={"Automatic"}>Automatic</Select.Option>
                        <Select.Option value={"Manual"}>Manual</Select.Option>
                    </Select>
                </div>
            </Col>

            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Vehicle Type <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.vehicleType}
                        onChange={(value)=>handleDropdownChange('vehicleType' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        options={vehicleTypes}
                    />
                </div>
            </Col>

            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Fuel Type <span>*</span> : </p>
                    <Select
                        showSearch
                        value = {form.fuelType || undefined}
                        onChange={(value)=>handleDropdownChange('fuelType' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select."
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {fuelTypes.map((fuel,i)=>
                            <Select.Option key={i}  value={fuel}>{fuel}</Select.Option>
                        )}
                    </Select>
                </div>
            </Col>

            <Col span={6}>
                <div className="form-fields">
                    <p className="form-field-label">Classification : </p>
                    <Select
                        showSearch
                        value={form.classification}
                        onChange={(value) => handleDropdownChange("classification", value)}
                        style={{ width: "100%" }}
                        placeholder="Search to Select."
                        options={classifications}
                    />
                </div>
            </Col>

            {/* <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>Viewing Address : </p>
                    <Input
                        value={form.street}
                        onChange={text=>handleFieldChange('street' , text)}
                    />
                </div>
            </Col>

            <Col span={6}>
                <div className='form-fields'>
                    <p className='form-field-label'>City , Region : </p>
                    <Select
                        showSearch
                        value = {form.locationId || undefined}
                        disabled={!locations.data}
                        loading={locations.loading}
                        onChange={(value)=>handleDropdownChange('locationId' , value)}
                        style={{ width: '100%' }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {locations.data.map((el,i)=>
                            <Select.Option key={i} value={el._id}>
                                {`${el.city}, ${el.region_name}`}
                            </Select.Option>
                        )}
                    </Select>
                </div>
            </Col> */}
        </Row>
    )
}

export default RentalDetails