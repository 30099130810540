import axios from 'axios'
import Kjur from '../utils/jwt';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchTotalVerifiedCars = async (condition, isBidding) => {

    let conditionType = "";
    let isCommercial = false;

    if(condition == "Commercial") {
        conditionType = "Used";
        isCommercial = true;
    }else {
        conditionType = condition;
    }

    const data = {
        product_condition: conditionType,
        is_bidding: isBidding ?? null,
    }

    if(isCommercial) {
        data.is_commercial_vehicle = isCommercial;
    }

     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_verified?token=${encodedData}`,
        axiosConfig
    )
}
export const fetchTotalUnverifiedCars = async (condition, isBidding) => {
    let conditionType = "";
    let isCommercial = false;

    if(condition == "Commercial") {
        conditionType = "Used";
        isCommercial = true;
    }else {
        conditionType = condition;
    }

    const data = {
        product_condition: conditionType,
        is_bidding: isBidding ?? null,
    }

    if(isCommercial == true) {
        data.is_commercial_vehicle = true;
    }
    
    const encodedData = Kjur.encode(data);
    return await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_unverified?token=${encodedData}`,
      axiosConfig
    );
}
export const fetchTotalSoldCars = async (isBidding) => {
    const data = {
        is_bidding: isBidding ?? null,
    }
    const encodedData = Kjur.encode(data);

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_sold_products?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalDraftCars = async () => {

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_draft_products`,
        axiosConfig
    )
}

export const fetchTotalActiveCars = async () => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_active_products`,
        axiosConfig
    )
}
export const fetchTotalRentalCars = async () => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_rental_products`,
        axiosConfig
    )
}
export const fetchTotalRentalUnverifiedCars = async () => {
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_rental_unverified`,
        axiosConfig
    )
}

export const fetchTotalCarlist = async (condition, isBidding) => {
    const data = {
        // product_condition: condition,
        // is_bidding: isBidding ?? null,
    }
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_carlist?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalBanner = async () => {
    const data = {}
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_banner?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalDealer = async () => {
    const data = {}
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_dealer?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalDirectSeller = async () => {
    const data = {}
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_direct_seller?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalSubscribeEmail = async () => {
    const data = {}
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_subscribe_email?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchTotalUnsubscribeEmail = async () => {
    const data = {}
     const encodedData = Kjur.encode(data);
    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/dashboard/total_unsubscribe_email?token=${encodedData}`,
        axiosConfig
    )
}