import { Carousel, Col, Divider, Modal, Row } from 'antd'
import React , {useEffect , useState , useRef} from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment';
import {MdShare , MdOutlineArrowBackIos , MdOutlineArrowForwardIos , MdOutlineLink , MdEmail} from 'react-icons/md';
import {FaRegClock , FaPhoneAlt } from 'react-icons/fa'
import {IoMdPin } from 'react-icons/io'
import './custom.scss'

const RentalPreviewModal = (props) => {
    const {visible , onCancel , data }=props
    const [content , setContent] = useState()
    const slider = useRef();

    const next = () =>{
        slider.current.next()
    }

    const prev = () =>{
        slider.current.prev()
    }

    // console.log(data)

    useEffect(()=>{
        if(Object.keys(data).length){
            const contentState = convertFromRaw(data.product_description);
            const newContent = EditorState.createWithContent(contentState)
            console.log(newContent)
            setContent(newContent)
        }
    },[data])

    return (
        <Modal
            title="Bike Rental Preview"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            className='preview-modal rental'
            width={900}
        >
            <div className='view-rental-container'>
                <Row gutter={[15,15]}>
                    <Col span={14}>
                        <div>
                            <div className='product-header'>
                                <div>
                                    <p className='product-name'>{data.product_name}</p>
                                    <p className='vehicle-type'>{data.vehicle_type}</p>
                                </div>
                                <div className='share'>
                                    <MdShare/>
                                    <p>Share</p>
                                </div>
                            </div>
                            

                            {data.product_rental_type==="short_term"?
                                <div className='rental-pricing-div'>
                                    <div>
                                        <p className='label'>Daily</p>
                                        <p className='value'>S$ {data.product_short_term_details?.daily?.toLocaleString() ?? "N.A."}</p>
                                    </div>
                                    <div>
                                        <p className='label'>WeekEnd</p>
                                        <p className='value'>S$ {data.product_short_term_details?.weekend?.toLocaleString() ?? "N.A."}</p>
                                    </div>
                                    <div>
                                        <p className='label'>Weekly</p>
                                        <p className='value'>S$ {data.product_short_term_details?.weekly?.toLocaleString() ?? "N.A."}</p>
                                    </div>
                                    <div>
                                        <p className='label'>Monthly</p>
                                        <p className='value'>S$ {data.product_short_term_details?.monthly?.toLocaleString() ?? "N.A."}</p>
                                    </div>
                                </div>
                            :null}

                            {data.product_rental_type==="long_term"?
                                <div className='rental-pricing-div'>
                                    {data.product_long_term_details.one_yr > 0 ?
                                        <div>
                                            <p className='label'>1 year Lease</p>
                                            <p className='value'>S$ {data.product_long_term_details.one_yr} <span>/month</span></p>
                                        </div>
                                    :null}
                                    {data.product_long_term_details.two_yr > 0 ?
                                        <div>
                                            <p className='label'>2 year Lease</p>
                                            <p className='value'>S$ {data.product_long_term_details.two_yr.toLocaleString()} <span>/month</span></p>
                                        </div>
                                    :null}
                                    {data.product_long_term_details.three_yr > 0 ?
                                        <div>
                                            <p className='label'>3 year Lease</p>
                                            <p className='value'>S$ {data.product_long_term_details.three_yr.toLocaleString()} <span>/month</span></p>
                                        </div>
                                    :null}
                                    {data.product_long_term_details.four_yr > 0 ?
                                        <div>
                                            <p className='label'>4 year Lease</p>
                                            <p className='value'>S$ {data.product_long_term_details.four_yr.toLocaleString()} <span>/month</span></p>
                                        </div>
                                    :null}
                                    {data.product_long_term_details.fifth_yr > 0 ?
                                        <div>
                                            <p className='label'>5 year Lease</p>
                                            <p className='value'>S$ {data.product_long_term_details.fifth_yr.toLocaleString()} <span>/month</span></p>
                                        </div>
                                    :null}
                                </div>
                            :null}

                            {data.product_rental_type==="private_hire"?
                                <div className='rental-pricing-div'>
                                    <p className='value'>S$ {data.product_hire_rental_details.weekly.toLocaleString()} <span>/week</span></p>
                                    <p>Min. Months : {data.product_hire_rental_details.minimum_months} <span>month</span></p>
                                </div>
                            :null}

                            {data.product_rental_type==="wedding_car"?
                                <div className='rental-pricing-div'>
                                <div>
                                    <p className='label'>6 Hours</p>
                                    <p className='value'>S$ {data.product_wedding_rental_details?.six_hours.toLocaleString() ?? "N.A."}</p>
                                </div>
                                <div>
                                    <p className='label'>12 Hours</p>
                                    <p className='value'>S$ {data.product_wedding_rental_details?.twelve_hours.toLocaleString() ?? "N.A."}</p>
                                </div>
                            </div>
                            :null}
                        </div>
                        <div className='specs-container'>
                            <Row gutter={[15,15]} type="flex">
                                <Col span={8}>
                                    <div className='spec-container'>
                                        <p className='label'>Brand Model</p>
                                        <p className='value'>{data.product_brand_model?.model_name}</p>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='spec-container'>
                                        <p className='label'>Transmission</p>
                                        <p className='value'>{data.product_transmission}</p>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='spec-container'>
                                        <p className='label'>Fuel Type</p>
                                        <p className='value'>{data.fuel_type}</p>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='spec-container'>
                                        <p className='label'>Registration Date</p>
                                        <p className='value'>{moment(data.registration_date).format('YYYY/MM/DD')}</p>
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <div className='spec-container'>
                                        <p className='label'>Location</p>
                                        <p className='value'>{data.product_pickup_location?.address}, {data.product_pickup_location?.city} , {data.product_pickup_location?.region_name}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Divider/>
                        <div>
                            <p className='rental-desc'>Rental Descriptions</p>
                            <Editor editorState={content} readOnly toolbar={{options:[]}}/>
                        </div>
                        
                    </Col>
                    <Col span={10}>
                        <div className='image-container'>
                            {data.product_image_urls?.length?
                                <Carousel
                                    ref={ref => {
                                        slider.current = ref;
                                    }}
                                >
                                    {data.product_image_urls.map((image , i)=>
                                        <img key={i} src={image.metadata.image_url}/>
                                    )}
                                </Carousel>
                            :"FAILED TO LOAD IMAGES"}
                        </div>
                        <div className='slider-icon-container'>
                            <div className='slider-icon' onClick={prev}>
                                <MdOutlineArrowBackIos/>
                            </div>
                            <div className='slider-icon' onClick={next}>
                                <MdOutlineArrowForwardIos/>
                            </div>
                        </div>
                        <div className='company-container'>
                            <div className='company-header'>
                                <p>Company Information</p>
                            </div>
                            <div className='company-details'>
                                <p className='company-name'>{data.rental_company_details?.company_name}</p>
                                <div className='details-container'>
                                    <MdOutlineLink/>
                                    <p>{data.rental_company_details?.company_website_url}</p>
                                </div>
                                <div className='details-container'>
                                    <IoMdPin/>
                                    <p>{data.rental_company_details?.company_location?.street}, {data.rental_company_details?.company_location?.city}, {data.rental_company_details?.company_location?.region_name}</p>
                                </div>
                                <div className='details-container multi'>
                                    <FaRegClock/>
                                    <div>
                                        <p>Weekdays : {moment(data.rental_company_details?.company_hours?.weekdays.opening_time , "HH:mm:ss").format('LT')} - {moment(data.rental_company_details?.company_hours?.weekdays.closing_time , "HH:mm:ss").format('LT')}</p>
                                        <p>Weekends : {moment(data.rental_company_details?.company_hours?.weekends.opening_time , "HH:mm:ss").format('LT')} - {moment(data.rental_company_details?.company_hours?.weekends.closing_time , "HH:mm:ss").format('LT')}</p>
                                    </div>
                                    
                                </div>
                                <div className='details-container multi'>
                                    <FaPhoneAlt/>
                                    <div>
                                        {data.rental_company_details?.company_contact_details?.phone_number.map((el,i)=>
                                            <p>{el}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='details-container multi'>
                                    <MdEmail/>
                                    <div>
                                        <p>{data.rental_company_details?.company_email ? data.rental_company_details?.company_email : data.rental_company_details?.company_contact_details?.email_add}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ad-container'>
                            <div>
                                <p>CAR ADS HERE</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default RentalPreviewModal
