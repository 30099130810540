import { Checkbox, Input, Modal, Select, Col, Row } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { error, success } from "../../contants/snackbars";
import "./styles.scss";
import { UserContext } from "../../stores/contexts/userContext";
import {
  editAnnouncement,
  addAnnouncement,
  fetchAnnouncement,
} from "../../stores/api_calls/announcements";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
    CancelButton,
    IconButton,
    LoadingButton,
    PrimaryButton,
  } from "../../custom_components/customButton";
import { IoMdAddCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { colorScheme } from "../../contants/variables";
import { useLocation, useNavigate } from "react-router-dom";
import Kjur from '../../stores/utils/jwt'

const statuses = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const AnnounceForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const [isEditing, setIsEditing] = useState(false);
  const [userConfig, setUserConfig] = useContext(UserContext);
  const profile = userConfig.userDetails;
  const [requestLoading, setRequestLoading] = useState(false);
  const [announcement, setAnnouncement] = useState({});
  const [fetchLoading , setFetchLoading] = useState(false)

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const [form, setForm] = useState({
    id: "",
    description: "",
    is_active: true,
  });

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEditing(true);
      const parsedData = Kjur.decode(params.get("token"));
      console.log("PARAMS" , parsedData)
      setFetchLoading(true)

      const getAnnouncement = fetchAnnouncement(parsedData?.id);
      
      getAnnouncement.then((res) => {
          if(res.data.success){
              
            setAnnouncement(res.data.data)
              setFetchLoading(false)
          }
      }).catch((e)=>console.log(e))
    }
  }, []);

  useEffect(()=>{
        
    if(!fetchLoading && isEditing) {

        const parsedData = Kjur.decode(params.get('token'))
        
        const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
        // convert the raw state back to a useable ContentState object
        const emptyContent = convertFromRaw( JSON.parse( rawDraftContentState) );

        //converting the json to display in EDITOR
        const contentState = convertFromRaw( announcement.description ?? emptyContent);
        const newContent = EditorState.createWithContent(contentState)

        setForm({
          ...form,
          id: parsedData.id,
          is_active: parsedData.is_active,
        })
        setEditorState(newContent)
    }
  }, [fetchLoading])

  const onSubmit = () => {
    setRequestLoading(true);

    const rawDraftContentState = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const transformedData = form;
    transformedData.description = JSON.parse(rawDraftContentState);
    console.log(transformedData);
    if (isEditing) {
      const updateAnnouncement = editAnnouncement(transformedData);

      updateAnnouncement
      .then((res) => {
        if (res.data.success) {
          success("Announcement updated successfully");
          setRequestLoading(false);
          navigate("../announcement", { replace: true });
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
    } else {
      const createAnnouncement = addAnnouncement(transformedData);

      createAnnouncement
        .then((res) => {
          if (res.data.success) {
            success("Announcement successfully added");
            setRequestLoading(false);
            navigate("../announcement", { replace: true });
          }
        })
        .catch((e) => {
          setRequestLoading(false);
          error(
            `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
          );
        });
    }
  };

  return (
    <div className="career-form-container">
      <div className={`table-title-icon ${!isEditing ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEditing ? "add" : "edit"}`}>
        {!isEditing ? "Create Announcement" : `Edit Announcement`}
      </p>

      <div className="form-container">

        <div className="form-fields">
          <p className="form-field-label">
              Description <span>*</span> :{" "}
          </p>

          <div className="editor-container">
              <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{
                  inline: { inDropdown: false },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
              }}
              />
          </div>
        </div>

        <div className="form-fields">
          <p className="form-field-label">
            Status <span>*</span> :{" "}
          </p>
          <Select
            value={form.is_active}
            onChange={(value) => setForm({ ...form, is_active: value })}
            style={{ width: "50%" }}
            placeholder="Select status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {statuses.map((el, i) => (
              <Select.Option key={i} value={el.value}>
                {`${el.label}`}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="btn-div">
        <div className="btn">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              label={isEditing ? "Update Announcement" : "Create Announcement"}
              color={colorScheme.primary}
              onClick={() => onSubmit()}
              disabled={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnounceForm;
