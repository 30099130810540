import React , {useState , useEffect} from 'react'
import {FaTags} from 'react-icons/fa';
import { Tabs } from 'antd';
import CarTab from './CarTab';
import ClientTab from './ClientTab';
import VerifiedCarTab from './VerifiedCarTab';

const { TabPane } = Tabs;


const VerifiedRentalLists = () => {

    return (
        <div className='table-container'>
            <div className='table-title-icon sold'>
                <FaTags/>
            </div>
            <p className='table-title-text sold'>Rental Bike Lists</p>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Manage Rental Bikes" key="1">
                    {/* <CarTab/> */}
                    <VerifiedCarTab/>
                </TabPane>
                <TabPane tab="Manage Rental Company" key="2">
                    <ClientTab/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default VerifiedRentalLists;
