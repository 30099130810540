import React from 'react';
import { Checkbox, Col, Row } from 'antd'
import './rentals.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';

const RentalDescription = (props) => {

    const {editorState , setEditorState , handleEditorChange , onCheckboxChange , form , isEdit} = props

    const editorOptions = ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji']

    // const completeOptions = ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']

    return (
        <>
        <div className='editor-container'>
            <Editor 
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: editorOptions ,
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true }
                }}
            />
        </div>
        <Row gutter={[10,10]}>
            <Col span={6}>
                <div className='form-fields' style={{margin:"20px 0px 0"}}>
                    <Checkbox
                        valuePropName="checked"
                        checked={form.isVerified}
                        onChange={(e)=>onCheckboxChange('isVerified',e)}
                    >
                        <span style={{fontWeight:"bold",fontSize:16}}>Verify</span>
                    </Checkbox>
                </div>
            </Col>
        </Row>
        </>
    )
}

export default RentalDescription