import { useState, useEffect } from "react";
import { Modal } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const CareerPreviewModal = ({ visible, onCancel, data }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    if (Object.keys(data).length) {
      const contentState = convertFromRaw(data.job_description);
      const newContent = EditorState.createWithContent(contentState);
      setContent(newContent);
    }
  }, [data]);

  return (
    <Modal
      title="Bike Rental Preview"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      className="preview-modal rental"
      width={900}
    >
      <h1>{data.job_title}</h1>

      <div>
        <p className="rental-desc">Job Descriptions</p>
        <Editor editorState={content} readOnly toolbar={{ options: [] }} />
      </div>
    </Modal>
  );
};

export default CareerPreviewModal;
