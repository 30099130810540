import { Col, Row } from "antd";
import React, { useState, useRef } from "react";
import { FaUpload, FaDownload } from "react-icons/fa";
import { PrimaryButton } from "../../custom_components/customButton";
import { RiCloseCircleFill } from "react-icons/ri";
import { colorScheme } from "../../contants/variables";

const RentalUploads = (props) => {
  const fileUploader = useRef(null);
  const { isEdit, selectedFiles, setSelectedFiles, form, setForm, multiple } =
    props;
  const [error, setError] = useState({ isError: false, message: "" });

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedFiles([]);

    if (e.target.files) {
      const total = Array.from(e.target.files).reduce(
        (n, { size }) => n + size,
        0
      );
      const toMB = (total / 1048576).toFixed(2);
      const filesArray = Array.from(e.target.files);

      if (filesArray.length > 9) {
        setError({
          ...error,
          isError: true,
          message: "You only need to upload 9 images per car ad.",
        });
      } else if (toMB > 6) {
        setError({
          ...error,
          isError: true,
          message: "The maximum total size of all image in MB exceeded 6mb.",
        });
      } else {
        setSelectedFiles([...selectedFiles, ...filesArray]);
        setError({ ...error, isError: false, message: "" });
      }

      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
    }
  };

  const removeToSelectedFiled = (idx) => {
    const filteredArray = selectedFiles.filter((_, index) => index !== idx);
    setSelectedFiles(filteredArray);
  };

  const removeToCurrentImage = (idx) => {
    const filteredArray = form.imageUrls.filter((_, index) => index !== idx);
    setForm({ ...form, imageUrls: filteredArray });
  };

  return (
    <div>
      {error.isError ? (
        <p className="upload-error-text">{error.message}</p>
      ) : (
        ""
      )}

      <Row>
        <Col span={8}>
          <div className="upload-container col-div" onClick={handleOpenGallery}>
            <FaUpload />
            <p>UPLOAD MULTIPLE PHOTOS</p>
          </div>

          <input
            ref={fileUploader}
            id="getFile"
            type="file"
            hidden
            onChange={handleImageChange}
          />
        </Col>

        {selectedFiles
          .map((file) => URL.createObjectURL(file))
          .map((image, i) => (
            <Col span={8} key={i}>
              <div className="image-container col-div">
                <div
                  className="removeIcon"
                  onClick={() => removeToSelectedFiled(i)}
                >
                  <RiCloseCircleFill />
                </div>
                <img src={image} />
              </div>
            </Col>
          ))}
      </Row>

      {isEdit ? (
        <div>
          <div className="upload-header">
            <div className="header-div">
              <p className="header-text">Current Images</p>
            </div>
          </div>

          <Row>
            {form.imageUrls.map((image, i) => (
              <Col span={8} key={i}>
                <div className="image-container col-div">
                  <div
                    className="removeIcon"
                    onClick={() => removeToCurrentImage(i)}
                  >
                    <RiCloseCircleFill />
                  </div>
                  {/* {isEdit?
                                        <div
                                            className='downloadIcon'
                                        >
                                            <FaDownload/>
                                        </div>
                                    :null} */}
                  <img src={image.metadata.image_url} />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default RentalUploads;
