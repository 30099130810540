import { Col, Row } from 'antd';
import React , {useState, useEffect, useRef} from 'react'
import {FaUpload , FaDownload} from 'react-icons/fa'
import { LoadingButton, PrimaryButton } from '../../custom_components/customButton';
import {RiCloseCircleFill} from 'react-icons/ri';
import { colorScheme } from '../../contants/variables';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import JSZipUtils from 'jszip-utils';
import { download } from '../../stores/api_calls/upload';
import * as FileDownload from 'js-file-download'
import axios from 'axios';
import {
    validateLimitUpload,
  } from "../../stores/helpers/productFormValidation";

const UploadCarImages = (props) => {

    const fileUploader = useRef(null)
    const {isEdit , selectedFiles , setSelectedFiles , form , setForm } = props
    const [error , setError] = useState({isError:false , message:""})
    const [downloading , setDownloading] = useState(false)
    const [enableUploader, setEnableUploader] = useState(true);

    useEffect(() => {
        if (selectedFiles || form.imageUrls) {
          const value = validateLimitUpload(selectedFiles, form.imageUrls);
          if (value) {
            setEnableUploader(true);
          } else {
            setEnableUploader(false);
          }
        }
    }, [selectedFiles, form.imageUrls]);
    

    const handleOpenGallery = event => {
        fileUploader.current.click();
    };

    const handleImageChange = (e) => {
        // setSelectedFiles([])

        if(e.target.files){

            const total = Array.from(e.target.files).reduce((n, {size}) => n + size, 0)
            const toMB = (total/1048576).toFixed(2)
            const filesArray = Array.from(e.target.files)

            if(filesArray.length > 9){
                setError({...error , isError:true , message:"You only need to upload 9 images per bike ad."})
            }else if(toMB > 10){
                setError({...error , isError:true , message:"The maximum total size of all image in MB exceeded 10mb."})
            }else{
                setSelectedFiles([...selectedFiles, ...filesArray])
                setError({...error , isError:false , message:""})
            }

            Array.from(e.target.files).map(
                (file) => URL.revokeObjectURL(file)
            )
        }
    }

    const removeToSelectedFiled = (idx) => {
        const filteredArray = selectedFiles.filter((_,index)=>index!==idx)
        // console.log(filteredArray)
        setSelectedFiles(filteredArray)
    }

    const handleDownload = () => {
        setDownloading(true)
        const dl = download(form.id)
        dl.then((response)=>{
            if(response){
                if(form.condition == "Used") {
                    FileDownload(response.data, `${form.plateNumber.replaceAll("-","")}_${form.carName.replaceAll(" ","_")}.zip`)
                }else {
                    FileDownload(response.data, `${form.fullname.replaceAll("-","")}_${form.carName.replaceAll(" ","_")}.zip`)
                }
                
                setDownloading(false)
            }
        }).catch(e=>{
            console.log(e)
        })

        // const zip = JSZip();
        // var count = 0;
        // var zipFilename = `${form.carName.replaceAll(" ","")}.zip`;  
        // urls.forEach(function (url, i) {
        //     var filename = `${form.carName.replaceAll(" ","")}_${i+1}.png`;
        //     //   console.log(filename)
        //     // var cors_api_host = 'crossorigin.me/';
        //     var cors_api_host = 'cors-anywhere.herokuapp.com';
        //     var cors_api_url = `https://${cors_api_host}/${url}`;

        //     JSZipUtils.getBinaryContent(url, function (err, data) {
        //         if (err) {
        //         throw err;
        //         }
        //         zip.file(filename, data, { binary: true });
        //         count++;
        //         if (count == urls.length) {
        //         zip.generateAsync({ type: 'blob' }).then(function (content) {
        //             FileSaver.saveAs(content, zipFilename);
        //         });
        //         }
        //     });
        // });
    }
    const removeToCurrentImage = (idx) =>{
        const filteredArray = form.imageUrls.filter((_,index)=>index!==idx)
        setForm({...form , imageUrls:filteredArray})
    }


    return (
        <div>
            <div
                className='upload-header'
            >
                <div className='header-div'>
                    <p className='header-text'>Upload Pictures</p>
                    <p className='sub-header-text'>
                    Upload requirements: You can upload upto 9 images. Total image file size should not exceed <span style={{color:'red',fontWeight:'bold'}}>10MB </span> 
                    and it's dimension should not exceed (4800 x 3600)pixels. 
                    <span style={{color:'red',fontWeight:'bold'}}> Please noted that only PNG & JPG images are allowed.</span>
                    </p>
                </div>
            </div>

            {error.isError? <p className='upload-error-text'>{error.message}</p> : ""}

            <Row>
                {enableUploader && (
                    <Col span={8}>
                        <div className='upload-container col-div' onClick={handleOpenGallery}>
                            <FaUpload/>
                            <p>UPLOAD MULTIPLE PHOTOS</p>
                        </div>
                        
                        <input 
                            ref={fileUploader} 
                            id="getFile" 
                            type="file" 
                            hidden
                            multiple
                            onChange={handleImageChange}
                            accept=".jpg, .png, .jpeg"
                        />
                    </Col>
                )}
                

                {selectedFiles.map(file=>URL.createObjectURL(file)).map((image,i)=>
                    <Col span={8} key={i}>
                        <div className='image-container col-div'>
                            <span style={{position: "absolute", fontSize: 24}}>Image {i+1}</span>
                            <div
                                className='removeIcon'
                                onClick={()=>removeToSelectedFiled(i)}
                            >
                                <RiCloseCircleFill/>
                            </div>
                            <img src={image}/>
                        </div>
                    </Col>
                )}
            </Row>

            {isEdit?
                <div>
                    <div
                        className='upload-header'
                    >
                        <div className='header-div'>
                            <p className='header-text'>Current Images</p>
                        </div>
                        {isEdit?
                            <div className='download-div'>
                                {downloading?
                                    <LoadingButton label="Downloading Images"/>
                                    :<PrimaryButton onClick={handleDownload} color={colorScheme.green} label="Download All Image" Icon={<FaDownload style={{marginRight:'10px'}} />}/>
                                }
                            </div>
                        :null}
                        
                    </div>
                    
                    <Row>
                        {form.imageUrls.map((image,i)=>
                            <Col span={8} key={i}>
                                <div className='image-container col-div'>
                                    <div
                                        className='removeIcon'
                                        onClick={()=>removeToCurrentImage(i)}
                                    >
                                        <RiCloseCircleFill/>
                                    </div>
                                    {/* {isEdit?
                                        <div
                                            className='downloadIcon'
                                        >
                                            <FaDownload/>
                                        </div>
                                    :null} */}
                                    <img src={image.metadata.image_url}/>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            :null}
        </div>
    )
}

export default UploadCarImages
