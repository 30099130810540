import React , {useState , useEffect} from 'react';
import { Input, Spin, Table , notification } from 'antd';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle} from 'react-icons/md';
import {FaTags} from 'react-icons/fa';
import {FiSearch } from 'react-icons/fi';
import './brands.scss';
import { deleteBrand, fetchBrands, fetchModels } from '../stores/api_calls/carlists';
import {RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment';
import Models from './Models';
import BrandForm from '../forms/brands/BrandForm';
import {ModelAddForm} from '../forms/brands/ModelForm';
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { colorScheme } from '../contants/variables';
import { error, success } from '../contants/snackbars';

const BrandIndex = (props) => {

    const [keywordSearch , setKeywordSearch] = useState("")
    const [isLoading , setIsLoading] = useState(false)
    const [tableData,setTableData] = useState([])
    const [showModal , setShowModal] = useState({
        showBrandDelete:false , 
        showModelDelete:false , 
        showBrandForm:false, 
        showModelForm:false , 
        data:{} , 
        type:"Brand"
    })
    const [isEdit , setIsEdit] = useState(false)
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:"",
        defaultPageSize: 10, 
        showSizeChanger: true, 
        showQuickJumper:true,
        showTotal: total => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30']
    })
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [isExpanded , setIsExpanded] = useState(false)
    const [models , setModels] = useState({data:[] , isLoading:false})

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { 
            title: 'No. of Models', 
            dataIndex: 'number_of_models', 
            key: 'number_of_models' , 
            render:(text,record)=>record.number_of_models > 0 ? record.number_of_models : "No Model" 
        },
        { 
            title: 'Date Created', 
            dataIndex: 'date_created', 
            key: 'date_created' , 
            render:(text,record)=><span>{moment(record.date_created).format('LL')}</span>
        },
        { title: 'Created By', dataIndex: 'created_by', key: 'created_by' },
        { 
            title: 'Date Updated', 
            dataIndex: 'date_updated', 
            key: 'date_updated' , 
            render:(text,record)=><span>{moment(record.date_updated).format('LL')}</span>
        },
        { title: 'Updated By', dataIndex: 'updated_by', key: 'updated_by' },

        {
          title: 'Action',
          dataIndex: 'actions',
          key: 'actions',
          render: (text , record) => (
            <div className='actions-icons-container'>
                <IconButton className='icon-button add' tootTipTitle="Add Model" Icon={<MdAddCircle/>} onClick={()=>handleAddModel(record)}/>
                <IconButton className='icon-button edit' tootTipTitle="Edit Brand" Icon={<RiEdit2Fill/>} onClick={()=>handleEditBrand(record)}/>
                <IconButton className='icon-button delete' tootTipTitle="Delete Brand" Icon={<RiDeleteBin2Fill/>} onClick={()=>handleDeleteBrand(record)}/>
            </div>
          ),
        },
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    useEffect(()=>{
        if(expandedRowKeys.length){
            getModels()
        }
    },[expandedRowKeys])

    const onTableRowExpand = (expanded, record) => {
        const keys = [];
        if(expanded){
            keys.push(record._id); 
        }
        setExpandedRowKeys(keys);
        setIsExpanded(expanded)
    }

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {
        setIsLoading(true)
        const brands = fetchBrands(data)
        brands.then((res)=>{
            if(res.data.success){
                setTableData(res.data.data)
                setIsLoading(false)
                setTablePagination({
                    ...tablePagination , 
                    total:res.data.meta.pagination.total
                })
            }
        }).catch((e)=>console.log(e))
    }

    const getModels = () => {
        setModels({...models , isLoading:true})
        const onGetModels = fetchModels(expandedRowKeys[0]);
        onGetModels.then((res)=>{
            if(res.data.success){
                setModels({...models , isLoading:false , data:res.data.data})
            }
        }).catch((e)=>{
            console.log(e)
            setModels({...models , isLoading:false})
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const handleAddBrand = () => {
        setIsEdit(false)
        setShowModal({...showModal , showBrandForm:true , type:"Brand" })
    }
    const handleAddModel = (record) => {
        setIsEdit(false)
        setShowModal({...showModal , showModelForm:true , type:"Model" , data:record})
    }

    const handleEditBrand = (record) => {
        setIsEdit(true)
        setShowModal({...showModal , showBrandForm:true , type:"Brand" , data:record})
    }

    const handleDeleteBrand = (record) => {
        setShowModal({...showModal , showBrandDelete:true , type:"Brand" , data:record})
    }

    const onDeleteSubmit = () => {
        const brand = deleteBrand(showModal.data._id)
        brand.then((res)=>{
            if(res.data.success){
                refetchLists()
                success("Brand Deleted")
                setShowModal({...showModal , showBrandDelete:false , type:"Brand"})
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }
      
    return (
        <div className='brand-table-container'>
            <div className='table-title-icon primary'>
                <FaTags/>
            </div>
            <p className='table-title-text primary'>Bike Brand Lists</p>

            <div
                className='search-container'
            >
                <div className='add-button-container'>
                    <PrimaryButton Icon={<MdAddCircle/>} color={colorScheme.primary} label="Add New Brand" onClick={()=>handleAddBrand()}/>
                </div>

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Press Enter to Search Record'
                    prefix={<FiSearch/>}
                    value={keywordSearch}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>
            
            <div className='table-div'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onTableRowExpand}
                    expandable={{
                        expandedRowRender: record  => 
                            isExpanded?
                            <div style={{ margin: 0 }}>
                                <Models 
                                    itemId={record._id}
                                    models={models}
                                    getModels={getModels}
                                />
                            </div>:null,
                        rowExpandable: record => record.number_of_models !== 0,
                    }}
                    dataSource={tableData}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    pagination={tablePagination}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />,
            </div>

            <BrandForm
                isEdit={isEdit}
                visible={showModal.showBrandForm}
                fetchData={fetchData}
                tablePagination={tablePagination}
                onCancel={()=>{
                    setShowModal({...showModal , showBrandForm:false , data:{}})
                    setIsEdit(false)
                }}
                data={showModal.data}
            />
            <ModelAddForm
                visible={showModal.showModelForm}
                getModels={getModels}
                refetchLists={refetchLists}
                onCancel={()=>{
                    setShowModal({...showModal , showModelForm:false , data:{}})
                    setIsEdit(false)
                }}
                data={showModal.data}
            />
            <CustomDeleteModal
                title={`Delete ${showModal.type}`}
                visible={showModal.showBrandDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>{
                    setShowModal({...showModal , showBrandDelete:false , data:{}})
                    setIsEdit(false)
                }}
                data={{itemName:`${showModal.data.name}` , type:showModal.type}}
            />

        </div>
    )
}

export default BrandIndex
