import React , {useState , useEffect} from 'react';
import { fetchTotalCarlist } from '../../stores/api_calls/dashboard';
import {
    FaCar,
  } from "react-icons/fa";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './card.scss'
import { useNavigate } from 'react-router-dom';

const TotalCarList = () => {

    const navigate = useNavigate()
    const [totalCars , setTotalCars] = useState()
    const [isLoading , setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true)
        const getActiveCars = fetchTotalCarlist()

        getActiveCars.then((res)=>{
            if(res.data){
                if(res.data.success){
                    // console.log(res.data.data);
                    setTotalCars(res.data.data)
                    setIsLoading(false)
                }
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    return (
        <div>
            <div className='total-carlist header-card'>
                <p className='header-text'>Total <br/>Bikelist</p>
                <FaCar style={{width:"80px"}}/>
            </div>
            <div className='total-carlist body-card'>
                <p className='active-text'>Total : {isLoading ? 'fetching' : !isLoading&&totalCars? totalCars.total_cars : 0}</p>
                <div className='goIcon' onClick={()=>navigate("../rentals/verified", { replace: true })}>
                    <AiOutlineArrowRight/>
                </div>
            </div>
        </div>
    )
}

export default TotalCarList
