import { EditorState , convertFromRaw, convertToRaw } from 'draft-js';
import './rentals.scss';
import React , {useRef , useEffect , useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {RiImageAddFill} from 'react-icons/ri';
import { IoMdAddCircleOutline , IoMdCloseCircle} from 'react-icons/io';
import moment from 'moment'
import Kjur from '../../stores/utils/jwt'
import { Col, DatePicker, Divider, Input, Row, Select, Space } from 'antd';
import { colorScheme, fuelTypes, rentalTypes, vehicleTypes, classifications } from '../../contants/variables';
import RentalTypes from './RentalTypes';
import RentalDetails from './RentalDetails';
import RentalRequirements from './RentalRequirements';
import RentalDescription from './RentalDescription';
import RentalUploads from './RentalUploads';
import { fetchBrands, fetchCities, fetchModels } from '../../stores/api_calls/carlists';
import { addRentalProduct, editRentalProduct, fetchRentalClients, fetchRentalCarDetails } from '../../stores/api_calls/rentals';
import { error, success } from '../../contants/snackbars';
import { LoadingButton, PrimaryButton } from '../../custom_components/customButton';
import { uploadImage } from '../../stores/api_calls/upload';

const ProductRental = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const params = new URLSearchParams(location.search)
    const [isEdit , setIsEdit] = useState(false)
    const [brands , setBrands] = useState({loading:false , data:[]})
    const [models , setModels] = useState({loading:false , data:[]})
    const [locations , setLocations] = useState({loading:false , data:[]})
    const [companies, setCompanies] = useState({loading:false , data:[]})
    const [requestLoading , setRequestLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [selectedFiles , setSelectedFiles] = useState([])
    const [convertedContent, setConvertedContent] = useState(null);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(),
    );

    const [form , setForm] = useState({
        productId:"",
        productName:"",
        companyId:"",
        brandId:"",
        modelId:"",
        vehicleType:"",
        classification: "",
        fuelType:"",
        productEdition:"",
        rentalType:"short_term",
        transmission:"",
        regDate:moment(),
        productDescription:{},
        imageUrls:[],

        shortDaily:0,
        shortWeekend:0,
        shortWeekly:0,
        shortMonthly:0,

        long1Yr:0,
        long2Yr:0,
        long3Yr:0,
        long4Yr:0,
        long5Yr:0,

        hireWeekly:0,
        hireMinMonths:0,

        wedding6hr:0,
        wedding12hr:0,

        locationId:"",
        street:"",
        city:"",
        region:"",

        allowedPPlate:false,
        allowedDriveToMalaysia:false,
        zeroDeposit:false,
        allowed21yrOld:false,
        drivingExperience:"",
        minimumBooking:"",

        isVerified:"",
    })

    useEffect(() => {
        if(location.pathname.includes("/edit")){
            setIsEdit(true)
        }
    },[])

    useEffect(()=>{
        onGetCities()
        onGetCompanies()
        onGetBrands()
    },[])

    useEffect(() => {
        if (isEdit) {
            getCarDetails();
        }
    }, [isEdit])

    const getCarDetails = async () => {
        setIsLoading(true)
        const onGetCarDetails = fetchRentalCarDetails(params.get("id"));
        onGetCarDetails
      .then((res) => {
        if (res.data.success) {
          const parsedData = res.data.data[0];

          const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
          // convert the raw state back to a useable ContentState object
          const emptyContent = convertFromRaw( JSON.parse( rawDraftContentState) );

          //converting the json to display in EDITOR
          const contentState = convertFromRaw( parsedData?.product_description ?? emptyContent);
          const content = EditorState.createWithContent(contentState)
        
          setForm({
            ...form,
            productId:parsedData._id,
            productName:parsedData.product_name,
            companyId:parsedData.rental_company_details?._id,
            brandId:parsedData.product_brand?._id,
            modelId:parsedData.product_brand_model?._id,
            vehicleType:parsedData.vehicle_type,
            classification:parsedData.classification,
            fuelType:parsedData.fuel_type,
            productEdition:parsedData.product_edition,
            rentalType:parsedData.product_rental_type,
            transmission:parsedData.product_transmission,
            regDate:parsedData.registration_date,
            productDescription:parsedData.product_description,
            imageUrls:parsedData.product_image_urls,

            shortDaily:parsedData.product_short_term_details?.daily,
            shortWeekend:parsedData.product_short_term_details?.weekend,
            shortWeekly:parsedData.product_short_term_details?.weekly,
            shortMonthly:parsedData.product_short_term_details?.monthly,

            long1Yr:parsedData.product_long_term_details?.one_yr,
            long2Yr:parsedData.product_long_term_details?.two_yr,
            long3Yr:parsedData.product_long_term_details?.three_yr,
            long4Yr:parsedData.product_long_term_details?.four_yr,
            long5Yr:parsedData.product_long_term_details?.fifth_yr,

            hireWeekly:parsedData.product_hire_rental_details?.weekly,
            hireMinMonths:parsedData.product_hire_rental_details?.minimum_months,

            wedding6hr:parsedData.product_wedding_rental_details?.six_hours,
            wedding12hr:parsedData.product_wedding_rental_details?.twelve_hours,

            locationId:"",
            street:parsedData.product_pickup_location?.address,
            city:parsedData.product_pickup_location?.city,
            region:parsedData.product_pickup_location?.region_name,

            allowedPPlate:parsedData.product_rental_requirements?.allowed_p_plate,
            allowedDriveToMalaysia:parsedData.product_rental_requirements?.allowed_drive_to_malaysia,
            zeroDeposit:parsedData.product_rental_requirements?.zero_deposit,
            allowed21yrOld:parsedData.product_rental_requirements?.allowed_21yr_old,
            drivingExperience:parsedData.product_rental_requirements?.driving_experience,
            minimumBooking:parsedData.product_rental_requirements?.minimum_booking,

            isVerified:parsedData.is_verified,
          });
          setIsLoading(false)
          setEditorState(content)
        }
      })
      .catch((e) => console.log(e));
    }

    // useEffect(()=>{
    //     if(isEdit){

    //         const parsedData = Kjur.decode(params.get('token'))

    //         const rawDraftContentState = JSON.stringify( convertToRaw(editorState.getCurrentContent()) );
    //         // convert the raw state back to a useable ContentState object
    //         const emptyContent = convertFromRaw( JSON.parse( rawDraftContentState) );

    //         //converting the json to display in EDITOR
    //         const contentState = convertFromRaw( parsedData?.product_description ?? emptyContent);
    //         const content = EditorState.createWithContent(contentState)

    //         setForm({
    //             ...form,
    //             productId:parsedData._id,
    //             productName:parsedData.product_name,
    //             companyId:parsedData.rental_company_details._id,
    //             brandId:parsedData.product_brand._id,
    //             modelId:parsedData.product_brand_model._id,
    //             vehicleType:parsedData.vehicle_type,
    //             fuelType:parsedData.fuel_type,
    //             productEdition:parsedData.product_edition,
    //             rentalType:parsedData.product_rental_type,
    //             transmission:parsedData.product_transmission,
    //             regDate:parsedData.registration_date,
    //             productDescription:parsedData.product_description,
    //             imageUrls:parsedData.product_image_urls,

    //             shortDaily:parsedData.product_short_term_details.daily,
    //             shortWeekend:parsedData.product_short_term_details.weekend,
    //             shortWeekly:parsedData.product_short_term_details.weekly,
    //             shortMonthly:parsedData.product_short_term_details.monthly,

    //             long1Yr:parsedData.product_long_term_details.one_yr,
    //             long2Yr:parsedData.product_long_term_details.two_yr,
    //             long3Yr:parsedData.product_long_term_details.three_yr,
    //             long4Yr:parsedData.product_long_term_details.four_yr,
    //             long5Yr:parsedData.product_long_term_details.fifth_yr,

    //             hireWeekly:parsedData.product_hire_rental_details.weekly,
    //             hireMinMonths:parsedData.product_hire_rental_details.minimum_months,

    //             wedding6hr:parsedData.product_wedding_rental_details.six_hours,
    //             wedding12hr:parsedData.product_wedding_rental_details.twelve_hours,

    //             locationId:"",
    //             street:parsedData.product_pickup_location.address,
    //             city:parsedData.product_pickup_location.city,
    //             region:parsedData.product_pickup_location.region_name,

    //             allowedPPlate:parsedData.product_rental_requirements.allowed_p_plate,
    //             allowedDriveToMalaysia:parsedData.product_rental_requirements.allowed_drive_to_malaysia,
    //             zeroDeposit:parsedData.product_rental_requirements.zero_deposit,
    //             allowed21yrOld:parsedData.product_rental_requirements.allowed_21yr_old,
    //             drivingExperience:parsedData.product_rental_requirements.driving_experience,

    //             isVerified:parsedData.is_verified,
    //         })

    //         setEditorState(content)
    //     }
    // },[isEdit])

    useEffect(()=>{
        if(form.brandId){
            onGetModels()
        }
    },[form.brandId])

    useEffect(()=>{
        if(form.locationId && isLoading){
            locations.data.forEach(city=>{
                if(city._id===form.locationId){
                    setForm({...form , city:city.city , region:city.region_name})
                }
            })
        }
    },[form.locationId, isLoading])

    useEffect(()=>{
        if(locations.data.length && isEdit){
            locations.data.forEach(city=>{
                if(city.city===form.city){
                    setForm({...form , locationId:city._id})
                }
            })
        }
    },[locations.data, isLoading])

    const onGetCities = () => {
        setLocations({...locations , loading:true})
        const getCities = fetchCities()
        getCities.then((res)=>{
            if(res.data.success){
                setLocations({...locations , loading:false , data:res.data.data})
            }
        }).catch(e=>console.log(e))
    }

    const onGetCompanies = () => {
        setCompanies({...companies , loading:true})
        const obj = {keyword:"" , limit:10000 , page :1}
        const getCompanies = fetchRentalClients(obj)
        getCompanies.then((res)=>{
            if(res.data.success){
                setCompanies({...companies , loading:true , data:res.data.data})
            }
        }).catch(e=>console.log(e))
    }

    const onGetBrands = () => {
        setBrands({...brands , loading:true})
        const obj = {keyword:"" , limit:10000 , page :1}
        const getBrands = fetchBrands(obj)
        getBrands.then((res)=>{
            if(res.data.success){
                setBrands({...brands , loading:false , data:res.data.data})
            }
        }).catch(e=>console.log(e))
    }

    const onGetModels = () => {
        setModels({...models , loading:true})
        const getModels = fetchModels(form.brandId)
        getModels.then((res)=>{
            if(res.data.success){
                setModels({...models , loading:false , data :res.data.data})
            }
        }).catch(e=>console.log(e))
    }

    const handleFieldChange = (name , e) => {
        setForm({ ...form, [name] : e.target.value })
    }

    const onDateFieldChange = (name , dateString) => {
        setForm({...form , [name] : dateString })
    }

    const handleDropdownChange = (name , value) => {
        setForm({ ...form, [name] : value })
    }

    const onCheckboxChange = (name , e) => {
        setForm({ ...form, [name] : e.target.checked })
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const handleSubmit = () => {

        setRequestLoading(true)
        const content = convertToRaw(editorState.getCurrentContent())
        const imagesIds = isEdit ? form.imageUrls.map((img)=>img._id["$oid"]) : []

        if(selectedFiles.length){
            selectedFiles.forEach((file,index)=>{
                const waterMark = true;
                const upload = uploadImage(file, index+1, waterMark)

                upload.then((res)=>{
                    if(res.data.success){
                        imagesIds.push(res.data.data._id)
                    }

                    if((imagesIds.length - form.imageUrls.length)===selectedFiles.length){
                        const rentalProduct = isEdit? editRentalProduct(form , content ,imagesIds): addRentalProduct(form , content ,imagesIds)
                        rentalProduct.then((res)=>{
                            if(res?.data?.success){
                                success(`Bike ${isEdit?"updated" : "added"} Successfully`)
                                setRequestLoading(false)
                                // navigate("../rentals", { replace: true })
                                if(form.isVerified) {
                                    navigate("../rentals/verified", { replace: true })
                                }else {
                                    navigate("../rentals/unverified", { replace: true })
                                }
                                
                            }
                        }).catch((e)=>{
                            setRequestLoading(false);
                            error(e.response.data.message)
                        })
                    }
                })
            })
        }else{
            const rentalProduct = isEdit? editRentalProduct(form , content ,imagesIds): addRentalProduct(form , content ,imagesIds)
            rentalProduct.then((res) => {
                if(res?.data?.success){
                    success(`Bike ${isEdit?"updated" : "added"} Successfully`)
                    setRequestLoading(false)
                    // navigate("../rentals", { replace: true })
                    if(form.isVerified) {
                        navigate("../rentals/verified", { replace: true })
                    }else {
                        navigate("../rentals/unverified", { replace: true })
                    }
                }
            }).catch((e) => {
                error(e.response.data.message)
                setRequestLoading(false)
            })
        }        
    }

    return (
      <div className="rental-form-container">
        <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
          <IoMdAddCircleOutline />
        </div>
        <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
          {!isEdit ? "Create Bike Rental" : `Edit Bike Rental`}
        </p>

        <div className="header-container">
          <p className="header">Bike Rental Information</p>
          <p className="sub-header">
          Note: (<span className="text-color-danger">*</span>) fields are required to be filled up
          </p>
        </div>

        <RentalDetails
          form={form}
          locations={locations}
          brands={brands}
          models={models}
          companies={companies}
          onDateFieldChange={onDateFieldChange}
          handleDropdownChange={handleDropdownChange}
          handleFieldChange={handleFieldChange}
          classifications={classifications}
        />

        <Divider />

        <div className="header-container">
          <p className="header">Bike Rental Type</p>
          <p className="sub-header">
          Note: (<span className="text-color-danger">*</span>) fields are required to be filled up
          </p>
        </div>

        <RentalTypes
          form={form}
          handleDropdownChange={handleDropdownChange}
          handleFieldChange={handleFieldChange}
        />

        <Divider />

        <div className="header-container">
          <p className="header" style={{ marginBottom: "0px" }}>
          Rider Requirements <span style={{ color: colorScheme.red }}>*</span>
          </p>
        </div>

        <RentalRequirements
          form={form}
          onCheckboxChange={onCheckboxChange}
          handleFieldChange={handleFieldChange}
          handleDropdownChange={handleDropdownChange}
        />

        <Divider />

        <div className="header-container">
          <p className="header" style={{ marginBottom: "0px" }}>
          Bike Rental Description{" "}
            <span style={{ color: colorScheme.red }}>*</span>
          </p>
        </div>

        <RentalDescription
          form={form}
          isEdit={isEdit}
          editorState={editorState}
          setEditorState={setEditorState}
          handleEditorChange={handleEditorChange}
          onCheckboxChange={onCheckboxChange}
        />

        <Divider />

        <div className="header-container">
          <p className="header" style={{ marginBottom: "0px" }}>
          Bike Rental Photos <span style={{ color: colorScheme.red }}>*</span>
          </p>
        </div>

        <RentalUploads
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          form={form}
          setForm={setForm}
          isEdit={isEdit}
        />

        <Divider />
        <div className="button-container">
          <div className="button">
            {requestLoading ? (
              <LoadingButton />
            ) : (
              <PrimaryButton
                color={colorScheme.primary}
                label={isEdit ? "Update Rental" : "Create Rental"}
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    );
}

export default ProductRental